import './App.scss';
import Footer from './components/Layout/Footer';
import Header from './components/Layout/Header';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import HomePage from './components/Home/HomePage';
import StudentSignup from './components/Student/StudentSignup';
import LayoutSettings from './components/Layout/LayoutSettings';
import EmployerSignup from './components/Employer/EmployerSignup';
import ViewMoreJobs from './components/Student/ViewMoreJobs';
import Login from './components/User/Login';
import Applications from './components/Student/Applications';
import Profile from './components/Student/Profile';
import HelpCenter from './components/Extras/HelpCenter';
import MyApplications from './components/Student/MyApplications';
import MessageStudent from './components/Student/MessageStudent';
import MessageEmployer from './components/Employer/MessageEmployer';
import MessageStudentSecond from './components/Student/MessageStudentSecond';
import MatchedJobs from './components/Student/MatchedJobs';
import MatchedInternships from './components/Student/MatchedInternships';
import Employer from './components/Employer/Employer';
// import EmployerApplication from './components/Employer/EmployerApplication';
import EmployerApplications from './components/EmployerNew/employerApplication';
import PostInternship from './components/EmployerNew/PostIntership';
import ApplicationDetailsRecived from './components/EmployerNew/ApplicationDetails';

// import PersonalDetails from "./components/Employer/PersonalDetails";
import Review from './components/Student/Review';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ForgotPassword from './components/Extras/ForgotPassword';
import ResetPassword from './components/Extras/ResetPassword';
import PostJobForm from './components/Employer/PostJobForm';
import Preview from './components/Employer/Preview';
import CheckOpening from './components/Employer/CheckOpening';
import Plan from './components/Extras/Plan';
import CoverLetter from './components/Employer/CoverLetter';
import AddResume from './components/Employer/AddResume';
import Header2 from './components/Layout/Header2';
import { loadUser, logoutUser } from './actions/userActions';
import RecoveryEmail from './components/Extras/RecoveryEmail';
import EmployerPreview from './components/Employer/EmployerPreview';
import ViewMoreInternships from './components/Student/ViewMoreInternships';
import StudentSavedjob from './components/Student/StudentSavedjob';
import ProtectedRoute from './components/ProtectedRoute';
import Notfound from './components/Notfound';
import EmployerprotectedRoute from './components/EmployerprotectedRoute';
import StudentprotectedRoute from './components/StudentprotectedRoute';
import AdminSignup from './components/Admin/AdminSignup';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
//import EmployerApplicationReceivedList from "./components/Employer/EmployerAplicationReceivedList";
import ApplicationDetails from './components/Student/applicationDetails';
import Support from './components/Student/Support';
import ContactUs from './components/Student/ContactUs';
import EmployerDetail from './components/Employer/EmployerDetail';
import CompanyDetail from './components/Employer/CompanyDetail';
import CandidateDetail from './components/Student/CandidateDetail';
import EditProfile from './components/EmployerNew/EditProfile';
import EditProfileCompany from './components/EmployerNew/EditProfileCompany';
import UserApplications from './components/Student_New/UserApplications';
import PreviewJob from './components/Student_New/PreviewJob';
import UserAppliedJob from './components/Student_New/UserAppliedJob';
import EditResume from './components/Student_New/EditResume';
import AppliedJobReview from './components/Student_New/AppliedJobReview';
import Job from './components/Student_New/Jobs';
import Internship from './components/Student_New/Internship';
import ChangePassword from './components/Dropdown/ChangePassword';
import ChangePasswordAdmin from './components/Dropdown/ChangePasswordAdmin';
import ChangeEmail from './components/Dropdown/ChangeEmail';
import { Helmet } from 'react-helmet';
import Terms from './components/Other/Tems';
import AboutUs from './components/Other/AboutUs';
import Privicy from './components/Other/Privicy';
import GDPR from './components/Other/GDPR';
import CandidateDetailEmp from './components/Student/CandidateDetailsEmp';
import Layout from './components/Layout/Layout';
import AdminCandidates from './components/Admin/AdminCandidates';
import AdminEmployers from './components/Admin/AdminEmployers';
import AdminPostedJobsAndInternships from './components/Admin/AdminPostedJobsAndInternships';
import AdminInputControl from './components/Admin/AdminInputControl';
import EmployerBuyConnect from './components/EmployerNew/EmployerBuyConnect';
import EmployerConnectInfo from './components/EmployerNew/EmployerConnectInfo';
import axios from 'axios';



// import {  useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
const checkDeleteLog = async (userId) => {
  try {
    const { data } = await axios.get('/api/admin/getDeleteUserIdInfo', {
      params: { userId }
    });
    return data.isDeleteHit;
  } catch (error) {
    console.error('Error checking delete log:', error);
    return false;
  }
};
const deleteDeleteLogEntry = async (userId) => {
  try {
    await axios.delete('/api/admin/deleteDeleteLogEntry', {
      data: { userId }
    });
  } catch (error) {
    console.error('Error deleting delete log entry:', error);
  }
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  const { user } = useSelector((state) => state.user);

  // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  // const userRole = useSelector((state) => state.user.role);
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token' && event.oldValue && !event.newValue) {
        // Token was removed, log out the user
        dispatch(logoutUser());
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Listen for logout events from other tabs
    const broadcastChannel = new BroadcastChannel("logout_channel");
    broadcastChannel.onmessage = (event) => {
      if (event.data === "logout") {
        // Logout message received, reload the page
        window.location.reload();
        // window.location.href = '/login';
      }
    };
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      broadcastChannel.close();
    };
  }, [dispatch]);

  useEffect(() => {
    const handleDeleteLogCheck = async () => {
      const localStorageId = localStorage.getItem('id');
      if (localStorageId) {
        const isDeleteHit = await checkDeleteLog(localStorageId);
        if (isDeleteHit) {
          localStorage.clear();
          dispatch(logoutUser());
          // await deleteDeleteLogEntry(localStorageId);
          window.location.href = '/login';
        }
      }
    };

    handleDeleteLogCheck();
  }, [user, dispatch]);
  return (

<>

    <Router>
      <LayoutSettings />
      <Layout>
      <Header2 expanded={true} />
      <div style= {{marginBottom: "78px"}}></div>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/studentSignup" element={<StudentSignup />} />
        <Route exact path="/employeeSignup" element={<EmployerSignup />} />
        <Route exact path="/login" element={<Login />} />

        {/*<Route path="/admin" element={<AdminDashboard />} />*/}
        <Route path="/AdminSignup" element={<AdminSignup />} />
        <Route path="/admin_login" element={<AdminLogin />} />
        <Route path="/admin_change_password" element={<ChangePasswordAdmin />} />
        <Route path="/admin_candidates" element={<AdminCandidates />} />
        <Route path="/admin_employers" element={<AdminEmployers />} />
        <Route path="/admin_input_control" element={<AdminInputControl />} />
        <Route path="/admin" element={<AdminPostedJobsAndInternships />} />

        {/* <Route exact path="/applications" element={<UserApplications />} /> */}
        <Route exact path="/applications" 
        element={<StudentprotectedRoute Component={UserApplications} />} />
        <Route exact path="/searchJobs" element={<ViewMoreJobs />} />
        <Route
          exact
          path="/searchInternships"
          element={<ViewMoreInternships />}
        />
        <Route exact path="/editProfileUser" element={<Profile />} />
        {/* <Route exact path="/myProfile" element={<EditProfile />} /> */}
        <Route exact path="/myProfile" 
          element={<EmployerprotectedRoute Component={EditProfile} />}/>
        {/* <Route
          exact
          path="/myProfile/company"
          element={<EditProfileCompany />}
        /> */}
        <Route
          exact
          path="/myProfile/company"
          element={<EmployerprotectedRoute Component={EditProfileCompany} />}
        />
        <Route exact path="/help" element={<HelpCenter />} />
        <Route exact path="/plan" element={<Plan />} />
        <Route exact path="/myApplications" element={<MyApplications />} />
        {/* <Route exact path="/messageStudent" element={<MessageStudent />} /> */}
        <Route exact path="/messageStudent" 
        element={<StudentprotectedRoute Component={MessageStudent} />} />
        <Route
          exact
          path="/messageStudentSecond"
          element={<MessageStudentSecond />}
        />

        <Route exact path="/matchedJobs/:filterType" element={<Job />} />

        {/* <Route exact path='/matchedJobs'  element={<ProtectedRoute Component={MatchedJobs} />} /> */}

        <Route exact path="/otpverification" element={<ForgotPassword />} />
        <Route exact path="/password/reset" element={<ResetPassword />} />
        <Route exact path="/matchedInternships/:filterType" element={<Internship />} />
        <Route exact path="/employer/*" element={<Employer />} />

        {/* <Route exact path='/employerApplication' element={<EmployerApplication />} /> */}
        <Route
          exact
          // path="/employerApplication"
          path="/employerApplication/:type"
          element={<EmployerprotectedRoute Component={EmployerApplications} />}
        />
        <Route
          exact
          path="/employerbuyconnects"
          element={<EmployerprotectedRoute Component={EmployerBuyConnect} />}
        />
        {/* <Route
          exact
          path="/employerbuyconnects"
          element={<EmployerBuyConnect/>}
        /> */}
        <Route
          exact
          path="/employerconnectsinformation"
          element={<EmployerprotectedRoute Component={EmployerConnectInfo} />}
        />
        <Route
          exact
          path="/employerappreceived/:type"
          element={
            <EmployerprotectedRoute Component={ApplicationDetailsRecived} />
          }
        />
        {/* <Route exact path="/employerappreceived" element={<EmployerprotectedRoute Component={EmployerApplicationReceivedList} />} /> */}

        <Route
          exact
          path="/applicationdetails"
          element={<EmployerprotectedRoute Component={ApplicationDetails} />}
        />
        {/* <Route
          exact
          path="/support"
          element={<EmployerprotectedRoute Component={Support} />}
        /> */}
        <Route
          exact
          path="/support"
          element={< Support/>}
        />
        <Route
          exact
          path="/messageEmployer"
          element={<EmployerprotectedRoute Component={MessageEmployer} />}
        />
        <Route
          exact
          path="/contactus"
          element={<StudentprotectedRoute Component={ContactUs} />}
        />

        <Route exact path="/checkopening" element={<CheckOpening />} />
        <Route exact path="/postjob" element={<PostJobForm />} />

          {/*Other Routes*/}

          <Route exact path="/term-condition" element={<Terms />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/privacy" element={<Privicy />} />
          <Route exact path="/GDPR" element={<GDPR />} />

        {/* <Route exact path='/personalDetails' element={<PersonalDetails />} /> 
        <Route
          exact
          path="/personalDetails"
          element={<EmployerprotectedRoute Component={PersonalDetails} />}
        />*/}
        <Route exact path="/change-email" element={<ChangeEmail />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        {/* <Route exact path="/personalDetails"element={<EmployerprotectedRoute Component={PersonalDetails} />}/> */}
        <Route
          exact
          path="/newpost/:id"
          element={<EmployerprotectedRoute Component={PostInternship} />}
        />

        <Route exact path="/review/:appliedJobId" element={<AppliedJobReview />} />
        <Route exact path="/preview/:jobId" element={<PreviewJob />} />
        <Route exact path="/emppreview" element={<EmployerPreview />} />

        {/* <Route exact path="/review" element={<AppliedJobReview />} />
        <Route exact path="/preview" element={<PreviewJob />} />
        <Route exact path="/emppreview" element={<EmployerPreview />} /> */}

        {/* <Route exact path='/coverletterpage' element={<CoverLetter />} /> */}
        {/* <Route 
          exact path='/coverletterpage' 
          element={isAuthenticated ? <CoverLetter /> : <Navigate to='/login' />}
        /> */}

        <Route
          exact
          path="/coverletterpage/:jobId"
          element={<ProtectedRoute Component={UserAppliedJob} />}
        />

        <Route
          exact
          path="/coverletterpage"
          element={<ProtectedRoute Component={CoverLetter} />}
        />

        {/*  <Route exact path="/addresume" element={<AddResume />} />*/}
        <Route exact path="/recoveryemail" element={<RecoveryEmail />} />

        {/* <Route exact path="/studentSavedjob" element={<StudentSavedjob />} /> */}
        <Route exact path="/studentSavedjob" 
        element={<StudentprotectedRoute Component={StudentSavedjob} />} />

        {/* <Route exact path="/addResume" element={<EditResume />} /> */}
        <Route exact path="/addResume" 
         element={<StudentprotectedRoute Component={EditResume} />} />
        <Route exact path="/recoveryemail" element={<RecoveryEmail />} />

        <Route exact path="/studentSavedjob" element={<StudentSavedjob />} />

        <Route exact path="/employer-details" element={<EmployerDetail />} />
        <Route exact path="/company-detail" element={<CompanyDetail />} />
        <Route exact path="/candidate-details" element={<CandidateDetail />} />
      {/*  <Route exact path="/candidate-details-emp" element={<CandidateDetailEmp />} />*/}

        <Route path="*" element={<Notfound />} />
      </Routes>
      <Footer expanded={true} />
      </Layout>
    </Router>
    </>
  );
}

export default App;
