import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePasswordAdmin = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    useEffect(() => {
        if(!localStorage.getItem('token')  || !localStorage.getItem('userType') || localStorage.getItem('userType') !== "Admin"){
          navigate("/admin_login");
        }
      });

  // State variables for old, new, and confirm passwords
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hoverop, setHoverop] = useState(false);
  const [hovernp, setHovernp] = useState(false);
  const [hovercp, setHovercp] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle password update
  const handleUpdatePassword = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error('Please fill all the fields.');
    }
    else   
    if (newPassword.length < 6 || confirmPassword.length < 6) {
        toast.error('Password should be atleast six character');
      }
    
else{
    // Calling backend API to update the password
    setLoading(true);
    try {
      const response = await fetch('/api/admin/change-password-admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email,
          oldpassword: oldPassword,
          newpassword: newPassword,
          conpassword: confirmPassword,
        }),
      });

      const data = await response.json();


      if (data.success) {
        toast.success('Password updated successfully.');
        // make all fields empty
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        toast.error(data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating password:', error);
      setLoading(false);
    }}
  };


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const myButton = document.querySelector('.changePassword');
        if (myButton) {
          myButton.click();
        } else {
          console.error('Button not found');
        }
      }
    };
    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyPress);
    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return (<>
    <div
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '130px',
          paddingBottom: '120px',
        }}
      >
        <p
          style={{
            fontWeight: '600',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Change Password
        </p>

        <div
          style={{
            fontWeight: '400',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #DDD',
            padding: '20px',
            borderRadius: '6px',
            width: '27%',
            marginTop: '20px',
            marginBottom: '20px',
            minWidth: '300px',
          }}
        >
          {/* Input field for old password */}
          <label htmlFor="old_password">Old password</label>
          <input
            type="password"
            name="old_password"
            id="old_password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            style={{
              // border: '1px solid #DDD',
              border: `1px solid ${hoverop ? '#00A5EC' : '#DDD'}`,
              boxShadow: 'none',
              padding: '11px',
              color: '#484848',
              borderRadius: '3px',
              fontSize: '14px',
              height: '40px',
              lineHeight: '1.14285714',
              textDecoration: 'none',
              outline: 'none',
            }}
            onMouseEnter={() => setHoverop(true)}
            onMouseLeave={() => setHoverop(false)}
          />

          {/* Input fields for new and confirm passwords */}
          <label htmlFor="new_password" style={{ marginTop: '10px' }}>
            New password
          </label>
          <input
            type="password"
            name="new_password"
            id="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onMouseEnter={() => setHovernp(true)}
            onMouseLeave={() => setHovernp(false)}
            style={{
              border: `1px solid ${hovernp ? '#00A5EC' : '#DDD'}`,
              boxShadow: 'none',
              padding: '11px',
              color: '#484848',
              borderRadius: '3px',
              fontSize: '14px',
              height: '40px',
              lineHeight: '1.14285714',
              textDecoration: 'none',
              outline: 'none',
            }}
          />

          <label htmlFor="confirm_password" style={{ marginTop: '10px' }}>
          Confirm password
          </label>
          <input
            type="password"
            name="confirm_password"
            id="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{
              border: `1px solid ${hovercp ? '#00A5EC' : '#DDD'}`,
              boxShadow: 'none',
              padding: '11px',
              color: '#484848',
              borderRadius: '3px',
              fontSize: '14px',
              height: '40px',
              lineHeight: '1.14285714',
              textDecoration: 'none',
              outline: 'none',
            }}
            onMouseEnter={() => setHovercp(true)}
            onMouseLeave={() => setHovercp(false)}
          />

          {/* Button to trigger password update */}
          {loading?(<>
          <button
            className= "changePassword"
            style={{
              background: '#B8B8B8', 
              borderRadius: '3px',
              border: '1px solid #B8B8B8',
              padding: '12px 16px',
              marginTop: '20px',
              color: '#FFF',
              fontWeight: '600',
              fontSize: '14px',
            }}
          >
            Update
          </button></>):(<>
           <button
            className= "changePassword"
            onClick={handleUpdatePassword}
            style={{
              background:'#00A5EC' ,
              borderRadius: '3px',
              border: '1px solid #00A5EC',
              padding: '12px 16px',
              marginTop: '20px',
              color: '#FFF',
              fontWeight: '600',
              fontSize: '14px',
            }}
          >
            Update
          </button>
          </>)}
        </div>
      </div>
    </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
 </> );
};

export default ChangePasswordAdmin;
