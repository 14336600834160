import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
    return (
        <div>
            <Helmet>
                <meta property="og:title" content="Hirekingdom - Jobs, Internship, full-time jobs, part-time jobs, work from home jobs in India across all startups, MNC companies" />
            </Helmet>
            {children}
        </div>
    );
};

export default Layout;

