export const Requesttype = {
  All: "ApplicationReceived",
  Shortlisted: "Shortlisted",
  Hired: "Hired",
  Ninterested: "NotIntrested",
};

export const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const marks2 = [
  {
    value: 50,
    label: "0",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 70,
    label: "70%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const marks3 = [
  {
    value: 0,
    label: "All applicants",
  },
  {
    value: 1,
    label: "Good match",
  },
  {
    value: 2,
    label: "Very good match",
  },
];

export const marks4 = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5000,
    label: "5K",
  },
  {
    value: 10000,
    label: "10K",
  },
  {
    value: 15000,
    label: "15K",
  },
  {
    value: 20000,
    label: "20K",
  },
  {
    value: 25000,
    label: "25K",
  },
];
