import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

function LayoutSettings() {
    const location = useLocation();

    // Route path, extended false/true, display true/false
    // { route: "/", header: { display: true, extended: true }, footer: { display: true, extended: true } }
    const [routeLayoutSetting] = useState([
        { route: "/studentSignup", header: { display: true, extended: true }, footer: { display: true, extended: true } },
        { route: "/employeeSignup", header: { display: true, extended: true }, footer: { display: true, extended: true } },
        { route: "/login", header: { display: true, extended: true }, footer: { display: true, extended: true, grey: true } }
       

    ]);

    useEffect(() => {
        let route = location.pathname;

        let pageSetting = routeLayoutSetting.find(e => e.route === route);
        document.body.classList.remove("no-header-extended");
        document.body.classList.remove("no-header");
        document.body.classList.remove("no-footer");
        document.body.classList.remove("no-footer-extended");
        document.body.classList.remove("footer-grey");
        
        if (!pageSetting) return;

        if (pageSetting.header.display) {
            document.body.classList.remove("no-header");

            if (pageSetting.header.extended) {
                document.body.classList.remove("no-header-extended");
            } else {
                document.body.classList.add("no-header-extended");
            }
        } else {
            document.body.classList.add("no-header");
        }

        if (pageSetting.footer.display) {
            document.body.classList.remove("no-footer");

            if (pageSetting.footer.extended) {
                document.body.classList.remove("no-footer-extended");
            } else {
                document.body.classList.add("no-footer-extended");
            }
            
            if (pageSetting.footer.grey) {
                document.body.classList.add("footer-grey");
            }
            else {
                document.body.classList.remove("footer-grey");
            }
        } else {
            document.body.classList.add("no-footer");
        }
    }, [location, routeLayoutSetting]);

    return (
        <></>
    )
}

export default LayoutSettings