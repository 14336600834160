import React from 'react'
import { Link } from 'react-router-dom'

function CoverLetterQuestion() {
    return (
        <>
            <p className="cover_label">
                Cover letter & availability question will be asked to every applicant by default. If you wish, you may ask two more customized questions as an assessment.
            </p>
            <p className="cover_label">
                Cover letter
            </p>
            <p className="cover_label">
                Why should you be hired for this role?
            </p>
            <p className="cover_label">Availablity</p>
            <p className="cover_label">
                Are you available for 6 months, starting immediately, for a full-time work from home internship? If not, what is the time period you are available for and the earliest date you can start this internship on?
            </p>

            <div className="input-box cover-letter-input-box">
                <label htmlFor="responsibility">Interns Responsibility</label>
                <textarea name="responsibility"></textarea>
            </div>

            <p className="help-center-message">Have any Question, Visit <Link to="/help">Help Center</Link></p>
        </>
    )
}

export default CoverLetterQuestion