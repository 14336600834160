import React, { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import "../css/postInternship.css";

const Dropdown = ({ type, placeholder, category, actual, setActual, options }) => {
  
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [manual, setManual] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (!dropdown && !options.includes(search) && search !== "Profile Not found. I'll enter") {
      setSearch("");
      setManual(false);
    }
  }, [dropdown]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setActual((prev) => ({ ...prev, [category]: search }));
    if (search && search !== "Profile Not found. I'll enter") setDropdown(true);
    else setDropdown(false);

    if (options.includes(search) || options.includes(actual[category])) {
      setManual(false);
    }
  }, [search]);

  useEffect(() => {
    setManual(false);
    setSearch("");
  }, [type]);

  return (
    <div ref={dropdownRef} className="d-flex gap-2 flex-column" style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <input
          required
          value={search === "Profile Not found. I'll enter" ? search : actual[category]}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          className="parent-dropdown2"
          placeholder={placeholder}
        />
        {manual && (
          <div
            onClick={() => {
              setSearch("");
              setManual(false);
            }}
            className="cross"
          >
            <RxCross1 />
          </div>
        )}
      </div>
      {manual && (
        <input
          required
          value={actual[category] === "Profile Not found. I'll enter" ? "" : actual[category]}
          onChange={(e) => setActual({ ...actual, [category]: e.target.value })}
          type="text"
          className="parent-dropdown2 mt-3"
          placeholder={
            type
              ? "Specify the required Job Title (e.g. Software Engineer)"
              : "Specify the required Internship profile (e.g. Android App Development)"
          }
        />
      )}

      <div id={category + "child"} className="dropdown" onClick={() => setDropdown(false)} style={{ display: `${dropdown ? "flex" : "none"}` }}>
        {options
          .filter((i) => actual[category].slice(0, -1) != i && i?.toLowerCase().includes(search.toLowerCase()))
          .map((option, index) => (
            <div onClick={() => setSearch(option)} key={index}>
              <span>{option}</span>
            </div>
          ))}
        <div
          onClick={() => {
            setSearch("Profile Not found. I'll enter");
            setManual(true);
            setDropdown(false);
          }}
        >
          <span style={{fontWeight: "500", color: "black"}}>Profile not found. I'll enter</span>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
