export const skillOptions = [
    { value: 'Adaptability', label: 'Adaptability' },
    { value: 'Agile/Scrum', label: 'Agile/Scrum' },
    { value: 'Android Development', label: 'Android Development' },
    { value: 'Angular', label: 'Angular' },
    { value: 'AR/VR', label: 'AR/VR' },
    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
    { value: 'AWS', label: 'AWS' },
    { value: 'Bash Scripting', label: 'Bash Scripting' },
    { value: 'Big Data', label: 'Big Data' },
    { value: 'Blockchain', label: 'Blockchain' },
    { value: 'C#', label: 'C#' },
    { value: 'C++', label: 'C++' },
    { value: 'CI/CD', label: 'CI/CD' },
    { value: 'Communication Skills', label: 'Communication Skills' },
    { value: 'Creativity', label: 'Creativity' },
    { value: 'CSS', label: 'CSS' },
    { value: 'Critical Thinking', label: 'Critical Thinking' },
    { value: 'Cybersecurity', label: 'Cybersecurity' },
    { value: 'Data Analysis', label: 'Data Analysis' },
    { value: 'Data Science', label: 'Data Science' },
    { value: 'DevOps', label: 'DevOps' },
    { value: 'Django', label: 'Django' },
    { value: 'Docker', label: 'Docker' },
    { value: 'Express.js', label: 'Express.js' },
    { value: 'Firebase', label: 'Firebase' },
    { value: 'Flask', label: 'Flask' },
    { value: 'Git', label: 'Git' },
    { value: 'Go (Golang)', label: 'Go (Golang)' },
    { value: 'Google Cloud Platform', label: 'Google Cloud Platform' },
    { value: 'GraphQL', label: 'GraphQL' },
    { value: 'Hibernate', label: 'Hibernate' },
    { value: 'HTML', label: 'HTML' },
    { value: 'IoT', label: 'IoT' },
    { value: 'Java', label: 'Java' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Jasmine', label: 'Jasmine' },
    { value: 'Jenkins', label: 'Jenkins' },
    { value: 'Jest', label: 'Jest' },
    { value: 'JSON', label: 'JSON' },
    { value: 'Kafka', label: 'Kafka' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'Laravel', label: 'Laravel' },
    { value: 'Leadership', label: 'Leadership' },
    { value: 'LESS', label: 'LESS' },
    { value: 'Machine Learning', label: 'Machine Learning' },
    { value: 'Machine Vision', label: 'Machine Vision' },
    { value: 'Microservices', label: 'Microservices' },
    { value: 'Mocha', label: 'Mocha' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'Nginx', label: 'Nginx' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'NoSQL', label: 'NoSQL' },
    { value: '.NET', label: '.NET' },
    { value: 'OpenStack', label: 'OpenStack' },
    { value: 'Other', label: 'Other' },
    { value: 'Perl', label: 'Perl' },
    { value: 'PHP', label: 'PHP' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'PowerShell', label: 'PowerShell' },
    { value: 'Problem Solving', label: 'Problem Solving' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Python', label: 'Python' },
    { value: 'React', label: 'React' },
    { value: 'Redux', label: 'Redux' },
    { value: 'Responsive Design', label: 'Responsive Design' },
    { value: 'RESTful APIs', label: 'RESTful APIs' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Ruby on Rails', label: 'Ruby on Rails' },
    { value: 'Rust', label: 'Rust' },
    { value: 'Sass', label: 'Sass' },
    { value: 'SQL', label: 'SQL' },
    { value: 'Spring Boot', label: 'Spring Boot' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Teamwork', label: 'Teamwork' },
    { value: 'TensorFlow', label: 'TensorFlow' },
    { value: 'Testing', label: 'Testing' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'Unity', label: 'Unity' },
    { value: 'Vue.js', label: 'Vue.js' },
    { value: 'WebAssembly', label: 'WebAssembly' },
    { value: 'WebSocket', label: 'WebSocket' },
    { value: 'Xamarin', label: 'Xamarin' },
    { value: 'YAML', label: 'YAML' },
    { value: 'Zsh Scripting', label: 'Zsh Scripting' },
];

export const options = [
    { value: "Accounting & Finance", label: "Accounting & Finance" },
    { value: "Advertising", label: "Advertising" },
    { value: "Business Service", label: "Business Service" },
    { value: "Chemical", label: "Chemical" },
    { value: "Civil", label: "Civil" },
    { value: "Computer Science", label: "Computer Science" },
    { value: "Content Writing ", label: "Content Writing " },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Electronics", label: "Electronics" },
    { value: "Engineering", label: "Engineering" },
    { value: "Health Care", label: "Health Care" },
    { value: "Legal", label: "Legal" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing", label: "Marketing" },
    { value: "Mechanical", label: "Mechanical" },
    { value: "Other", label: "Other" },
    { value: "Restaurant", label: "Restaurant" },
    { value: "Retail", label: "Retail" },
    { value: "Sales", label: "Sales" },
    { value: "Transportation", label: "Transportation" },
  ];
  
  export const predefinedOptions = [
    { value: "Android App Development", label: "Android App Development" },
    { value: "Web Development", label: "Web Development" },
    { value: "iOS App Development", label: "iOS App Development" },
    { value: "UI/UX Design", label: "UI/UX Design" },
    { value: "Data Science", label: "Data Science" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Full Stack Development", label: "Full Stack Development" },
    { value: "Game Development", label: "Game Development" },
    { value: "DevOps", label: "DevOps" },
    { value: "Cloud Computing", label: "Cloud Computing" },
    { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Digital Marketing", label: "Digital Marketing" },
    { value: "Artificial Intelligence", label: "Artificial Intelligence" },
    { value: "Blockchain Development", label: "Blockchain Development" },
    { value: "Network Administration", label: "Network Administration" },
    { value: "Database Management", label: "Database Management" },
    { value: "Content Writing", label: "Content Writing" },
    { value: "Graphic Design", label: "Graphic Design" },
    { value: "Video Editing", label: "Video Editing" },
    { value: "Mobile Game Development", label: "Mobile Game Development" },
    { value: "AR/VR Development", label: "AR/VR Development" },
    { value: "Embedded Systems", label: "Embedded Systems" },
  ];

  export const Degree = [
    "Matriculation (10th Grade)",
    "Intermediate (10+2)",
    "Diploma Courses",
    "Certificate Courses",
    "Bachelor of Arts (BA)",
    "Bachelor of Science (BSc)",
    "Bachelor of Commerce (BCom)",
    "Bachelor of Engineering (BE/BTech)",
    "Bachelor of Technology (BTech)",
    "Bachelor of Computer Applications (BCA)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Architecture (BArch)",
    "Bachelor of Pharmacy (BPharm)",
    "Bachelor of Education (BEd)",
    "Bachelor of Fine Arts (BFA)",
    "Master of Arts (MA)",
    "Master of Science (MSc)",
    "Master of Commerce (MCom)",
    "Master of Engineering (ME/MTech)",
    "Master of Technology (M.Tech)",
    "Master of Business Administration (MBA)",
    "Master of Computer Applications (MCA)",
    "Master of Philosophy (MPhil)",
    "Master of Social Work (MSW)",
    "Master of Laws (LLM)",
    "Doctor of Philosophy (PhD)",
    "Doctor of Medicine (MD)",
    "Doctor of Science (DSc)",
    "Doctor of Literature (DLit)",
    "Doctor of Letters (DLitt)",
    "Doctor of Education (EdD)",
    "Postgraduate Diploma Courses",
    "Integrated Courses (Dual Degree)"
]
  

export const AVAILABILITY = [
  "Immediate",
 "Within 1 Week",
 "Within 2 Weeks",
 "Within 3 Weeks",
 "Within 4 Weeks",
 "Within 5 Weeks",
 "Within 6 Weeks",
 "Within 7 Weeks",
 "Within 8 Weeks",
]

export const MONTH_DETAILS = ['Mon', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];