import React,{useEffect} from "react";



function AboutUs() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
      }, []);
    return (<>
    <div className="terms-of-use" style={{backgroundColor: "white"}}>
        <div style={{maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px"}} className="about-us-mobile-responsive">
<p>
HireKingdom: Transforming Talent Acquisition
<br/>
<br/>
<br/>
<b>The HireKingdom Advantage</b>
<br/>
<br/>
HireKingdom is an innovative platform at the forefront of revolutionizing talent acquisition in the digital age. With a mission to connect skilled professionals with top-tier opportunities seamlessly, HireKingdom employs cutting-edge technologies and forward-thinking strategies to bridge the gap between talent and employers.
<br/>
<br/>
At HireKingdom, we believe that talent is the cornerstone of every successful organization. Our platform is designed to empower both employers and job seekers by providing them with intuitive tools and resources tailored to their unique needs.
<br/>
<br/>
<b>Empowering Job Seekers</b>
<br/>
<br/>
For job seekers, HireKingdom offers a user-friendly interface where they can showcase their skills, experience, and aspirations effectively. Our advanced matching algorithms ensure that candidates are connected with opportunities that align with their expertise and career goals, streamlining the job search process and maximizing their potential for success.
<br/>
<br/>
<b>Empowering Employers</b>
<br/>
<br/>
For employers, HireKingdom provides access to a diverse pool of talent from across the globe. Our comprehensive suite of recruitment tools simplifies the hiring process, from posting job listings to screening candidates and scheduling interviews. By leveraging data-driven insights and predictive analytics, we empower employers to make informed hiring decisions and build high-performing teams.
<br/>
<br/>
<b>Innovation and Excellence</b>
<br/>
<br/>
What sets HireKingdom apart is our commitment to innovation and excellence in everything we do. We continuously strive to push the boundaries of traditional recruitment methods, embracing emerging technologies such as artificial intelligence and machine learning to deliver unparalleled results for our users.
<br/>
<br/>
Join HireKingdom today and unlock the next chapter in your career journey or find the perfect candidate to take your organization to new heights. Together, we're shaping the future of talent acquisition and building a kingdom where talent reigns supreme.
<br/>
<br/>
<br/>
<br/>

<b>Address:</b> 
<br/>
<br/>
4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India
<br/>
<br/>
<br/>
Contact Number
<br/>
India, Phone: (+91) 7428477468

  
</p>
</div>
</div>

    </>)
}

export default AboutUs;