import React, {useEffect} from 'react'
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Layout/Spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import './UserAppliedJob.scss';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import bulb from '../../assets/images/bulbicon.png';
import { data } from 'jquery';
import { loadUser } from '../../actions/userActions';
import { Helmet } from 'react-helmet';




function UserAppliedJob() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);
 

  const { state } = useLocation();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, []);
  if (state==null ) {
    navigate('/');
  }
  const [applyJob, setApplyJob] = useState({
    _id: state && state._id,
    coverLetter: "",
    availability: true,
    specifyAvailability: "",
    assessment: [],
  });


  const copytolastapplication = async()=>{
   const { data } = await axios.get("/api/user/getdatafromlastapplication", {
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
   if(data && data.length>0){
    setApplyJob({...applyJob, coverLetter: data[0].coverLetter})
   }
  }

  const handleChange = (e) => {
    setApplyJob({
      ...applyJob,
      [e.target.name]: e.target.value,
    });
  };



  const available = () => {
    setApplyJob({
      ...applyJob,
      availability: true
    });
  }


  const handleAssesmentChange = (e, data) => {
    // Check if the question already exists in the assessment array
    const questionIndex = applyJob.assessment.findIndex(item => item.Question === data);
  
    if (questionIndex !== -1) {
      // If the question exists, update its answer
      setApplyJob(prevState => ({
        ...prevState,
        assessment: prevState.assessment.map((item, index) => {
          if (index === questionIndex) {
            return {
              ...item,
              Answer: e.target.value
            };
          }
          return item;
        })
      }));
    } else {
      // If the question does not exist, push a new object into the array
      setApplyJob(prevState => ({
        ...prevState,
        assessment: [
          ...prevState.assessment,
          {
            Question: data,
            Answer: e.target.value
          }
        ]
      }));
    }
  }
  
  
  const getAnswerForKey = (questionKey) => {
    const foundQuestion = applyJob.assessment.find(item => item.Question === questionKey);
    return foundQuestion ? foundQuestion.Answer : ''; // Return answer if question is found, otherwise return empty string
  }
  

  const notAvailable = () => {
    setApplyJob({
      ...applyJob,
      availability: false
    });
  }


  const handleApplyJob = async () => {
    if (user && user) {

      if (user && (!user.phone || !user.availability)) {
        toast.error("Please update your profile")
        navigate('/editProfileUser')
      }

      else if (user && !user.resume) {
        toast.error("Please create your resume")
        navigate('/addResume')
      }
      else {
        setLoading(true);
        if (!applyJob.coverLetter) {
          setLoading(false);
          toast.error("Cover Letter is required!!");
        } else {
         try {
            await axios.post("/api/user/apply", {
              _id: applyJob._id,
              coverLetter: applyJob.coverLetter,
              availability: applyJob.availability,
              specifyAvailability: applyJob.specifyAvailability,
              assessment: applyJob.assessment,
            }, {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
            });
            setLoading(false);
            toast.success("Application Saved Successfully!", {
              style: {
                paddingTop: "18px",
                paddingBottom: "18px",
              },
            });
            const timer = setTimeout(() => {
              navigate('/applications')
            }, 3000);

            timer();
          } catch (error) {
            setLoading(false);
            toast.error(error.response.data, {
              style: {
                padding: "18px",
              },
            });
          }
        }
      }
    }
    else {
      toast.error("Please Login!!")
    }
  }

  // console.log(user.role)

  return (
    <>
      <Helmet>
       <meta property="og:title" content={`${state.profile} ${state.jobType === 'Remote' ? state.jobType + ' ' : ' '} ${state.opportunityType}`} />
       <title>{state.profile + " " + (state.jobType === 'Remote' ? state.jobType+" " : " ") + state.opportunityType}</title>
       </Helmet>

      <div className="appliedjobBody" style={{backgroundcolor: 'white'}}>
      <div className='pt-2' >
      <div className="step-content container" >
    
        <div className="sectionn  mb-5 mx-auto" style={{ maxWidth: "900px", backgroundColor: "transparent" }}>
        
          <div className="section1 " style={{boxShadow: "none", border: 'none'}} >
          <p className=" text-start " style={{ marginTop: "20px", fontSize: "30px", lineHeight: '35px', fontWeight: '600' }}>
            {state&&state.profile} {state&&state.opportunityType.toLowerCase()} at {state&&state.organisationName}
          </p>
          <hr style={{marginTop: "30px", marginBottom: "32px", height:'0.05px', color: "gray"}}/>
            <p style={{fontSize: '25px', fontWeight: '500' }}>Cover letter</p>
            <div class="form-group mt-4">
                <p style={{ fontWeight: '400', fontSize: "15px", marginTop: '10px' }}>Why should you be hired for this role?</p>
                <div style={{ display: "flex", flexWrap: 'wrap', marginTop: "-7px" }}>
                  {/* <i class="fa fa-clone mr-2" style={{ color: "#009ACB", marginTop: '5px', fontSize: "12px", cursor: "pointer" }} onClick={copytolastapplication} aria-hidden="true"></i> */}
                  {/* <p style={{ color: "#009ACB", fontSize: '15px', cursor: "pointer" }} onClick={copytolastapplication}>Copy from your last application & edit</p> */}
                </div>
              </div>

              <div>

      {/* <Accordion className= "accordianfortab" style={{backgroundColor: "#f1fdff", boxShadow: "none"}}> */}
      <Accordion className= "accordianfortab" style={{backgroundColor: "#e0ffe5", boxShadow: "none"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: "#009ACB", fontSize: "25px"}} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <img src={bulb} alt='' style={{width: '27px', height: "27px", marginTop: "-5px", marginRight: "6px"}} />
         {/* <span style={{color: "#009ACB"}}>See tips to answer this question</span> */}
         <span style={{color: "#009ACB"}}>Check out these tips to answer this question effectively:</span>
        </AccordionSummary>
        <AccordionDetails style={{marginTop: "-25px"}}>
          <hr style={{height: "0.1px", color: "#009ACB"}} />
         <ul style={{fontSize: "14px", marginTop:"15px"}}>
          {/* <li style={{marginTop: "10px"}}> 
            <span style={{fontWeight: "500"}}>Highlight your strengths</span> - mention any experience (internship/job experience or extra-curricular activities), 
            accomplishments, skills that are relevant to the role. 
          </li> */}
          <li style={{marginTop: "10px",listStyleType:'disc'}}> 
            <span style={{fontWeight: "500"}}>Highlight what excites you about the role and the company.</span>
          </li>
          {/* <li style={{marginTop: "10px"}}> 
          <span style={{fontWeight: "500"}}> Show enthusiasm</span> - mention what excites you about this role and the company. You can go through their website/ 
            social media to understand what interests you.
          </li> */}
          <li style={{marginTop: "10px",listStyleType:'disc'}}> 
          <span style={{fontWeight: "500"}}>Connect your personal values, career goals, and passions to what you’ve learned about the company.</span>
          </li>
          <li style={{marginTop: "10px",listStyleType:'disc'}}> 
          <span style={{fontWeight: "500"}}>Research their website and social media to identify specific aspects that interest you.</span>
          </li>
          {/* <li style={{marginTop: "10px"}}> 
          <span style={{fontWeight: "500"}}>Research their website and social media to identify specific aspects that interest you.</span>
          </li> */}
          <li style={{marginTop: "10px",listStyleType:'disc'}}> 
          <span style={{fontWeight: "500"}}>Mention any projects, initiatives, or company culture elements that resonate with you.</span>
          </li>
          <li style={{marginTop: "10px",listStyleType:'disc'}}> 
          <span style={{fontWeight: "500"}}>Explain how the role aligns with your skills and career aspirations.</span>
          </li>
          <li style={{marginTop: "10px"}}>
          <span style={{fontWeight: "500"}}>Craft your response authentically</span>
          </li>
          {/* <li style={{marginTop: "10px"}}>
          <span style={{fontWeight: "500"}}> Do not copy answers</span> from the internet.
          </li> */}
          </ul>
        </AccordionDetails>
      </Accordion>
 

    </div>

              <div class="" style={{ marginTop: "10px" }}>
                  <style>
                              {`
                    #coverLetter::placeholder {
                      color: #bababa;
                    }
                  `}
                  </style>
                <textarea style={{ fontSize: "14px", letterSpacing: '1px', padding: "12px", lineHeight: "23px" }} class="form-control" id='coverLetter' rows="7" name='coverLetter' value={applyJob.coverLetter} onChange={handleChange}

                  placeholder='Before employers even glance at your resume, they often encounter your response to this question. Approach it thoughtfully, incorporating pertinent details such as your skills, experience, and what draws you to the role.'></textarea>
              </div>
            </div>
          </div>
        </div>


        <div className="step-content container" style={{ marginTop: "-130px" }}>

        <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
        <div className="section1 " style={{boxShadow: "none", border: 'none'}} >
            <p style={{fontSize: '25px', fontWeight: '500' }}>Your availability</p>
           
            <p style={{fontWeight: '400', fontSize: "16px", marginBottom: "20px", color: "#545454", letterSpacing: "0.4px"}}>Confirm your availability</p>
              <div class="form-check">
                <input class="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={available} />
                <p style={{fontWeight: '400', fontSize: "15px", color: "#545454", letterSpacing: "0.4px"}}>
                Yes, I am available to join immediately
                </p>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => { notAvailable() }} />
                <p style={{fontWeight: '400', fontSize: "15px", color: "#545454", letterSpacing: "0.4px"}}>
                  <p> No <span style={{ color: "#929292" }}>(Please specify your availability)</span></p>
                </p>
              </div>
          
          {applyJob && applyJob.availability == true ?(<></>):(<>
            <div class="" style={{ marginTop: "15px", marginBottom: "20px" }}>
            <style>
                              {`
                    #specifyAvailability::placeholder {
                      color: #bababa;
                    }
                  `}
                  </style>
                <textarea style={{ fontSize: "14px", letterSpacing: '1px', padding: "12px", lineHeight: "23px" }} class="form-control" id='specifyAvailability' rows="6" name='specifyAvailability' value={applyJob.specifyAvailability} onChange={handleChange}

                  placeholder='eg. I am ready to start working in the New Delhi office immediately. However, I will need a week to relocate.'></textarea>
              </div>
          </>)}

          </div>
        </div>
      </div>


      <div className="step-content container" style={{ marginTop: "-115px" }}>
        <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
        <div className="section1 " style={{boxShadow: "none", border: 'none'}}>
      {state && state.question && state.question.q1 !== "" && (<>
    <p style={{ fontSize: '25px', fontWeight: '500', marginBottom: "-20px" }}>Assessment</p>
    
    {Object.keys(state.question).map((key, index) => (
     
     state.question[key] !== "" ? 

      <div key={key} className="form-group mt-5">
        <p style={{ fontWeight: '400', fontSize: "16px", color: "#545454", letterSpacing: "0.3px" }}>
         Q{index+1}. {state.question[key]}
        </p>
        <br />
        <div style={{ marginTop: "-40px" }}>
          <p style={{ color: "#929292", fontSize: "14px", marginTop: "10px", letterSpacing: "0.4px" }}>
            If you want to share any documents or files, Please upload it on 
            <a href="https://drive.google.com/drive/u/0/home" target="_blank" style={{ color: "#0986C4", cursor: "pointer", fontSize: "15px", marginLeft: '5px', marginRight:  "3px" }}>
              Google Drive
            </a> or 
            <a href='https://www.dropbox.com/' target='_blank' style={{ color: "#0986C4", cursor: "pointer", fontSize: "15px",  marginLeft: '5px', marginRight:  "5px" }}>
              DropBox
            </a> 
            and paste the public link in the answer.
          </p>
        </div>
        <div className="" style={{ marginTop: "-2px" }}>
          <textarea
            style={{ fontSize: "14px", letterSpacing: '1px', padding: "12px", lineHeight: "23px"}}
            className="form-control"
            id='assessment'
            rows="6"
            name='assessment'
            value={getAnswerForKey(state.question[key])}
            onChange={(e) => handleAssesmentChange(e, state.question[key])} // assuming you need to pass the key to handleChange
            placeholder="Enter text..."
          ></textarea>
        </div>
      </div>

    : null ))}
  </>
)}



          </div>
        </div>
        <div className='center text-center mt-4' style={{ margin: "auto", marginBottom: "-20px" }}>
          <button type="button" onClick={handleApplyJob}
            style={{
            fontSize: "16px",
            borderRadius: "3px",
            width: "115px"
             }}
            class="btn btn-info02"
          >
            {loading ? <Spinner /> : <span>Submit</span>}
          </button>
        </div>
      </div>
      </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />

    </>
  )
}

export default UserAppliedJob;