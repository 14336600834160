import React, { useState, useEffect } from 'react'
import './css/EmployeeSignup.scss'
import SignupImage1 from '../../assets/images/signupEmployee1.png'
import SignupImage2 from '../../assets/images/signupEmployee2.png'
import Spinner from '../Layout/Spinner'
import axios from 'axios'
import Recruiter from "../../assets/images/emp.png";
import GoogleIcon from "../../assets/images/icons/google.png";
import "../Student/css/StudentSignup.scss"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OAuth2Login from "react-simple-oauth2-login";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

function EmployerSignup() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const navigate = useNavigate();
  const [user, setUser] = useState({ role: "Employer" });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();


  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;
    if (!values.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!values.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
      isValid = false;
    }

    if (!values.password) {
      errors.password = "password is required";
      isValid = false;
    }

    else if (values.password.length < 6) {
      errors.password = "Password must be at least six characters long";
      isValid = false;
    }

    if (!values.phone) {
      errors.phone = "contact number is required";
      isValid = false;
    }
    else if (values.phone.length < 7 || values.phone.length > 16) {
      errors.phone = "Number must be between 7 and 16 digits long";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };



  const handleUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }


  const openAccordian = (event) => {
    console.log(event.target.parentNode);
    let body = event.target.parentNode.querySelector(".js-accordion-body")
    if (body.classList.contains("show")) {
      body.classList.remove("show");
    }
    else {
      body.classList.add("show");
    }
  }




  const signup = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isValid = validate(user);
    if (isValid) {
      try {
        const { data } = await axios.post("/api/user/otp", user);
        console.log(data);
        if (data.status === 404) {
          setLoading(false);
          toast.error("This email is already used by another user", {
            style: {
              padding: '18px',
            }
          });
        }
        else {
          setLoading(false);
        }

        navigate('/otpverification', { state: user })

      } catch (error) {
        console.log(error.response.data);
        setLoading(false);
        toast.error(error.response.data, {
          style: {
            padding: '18px',
          }
        });

      }



    } else {
      setLoading(false);
      const error = Object.values(formErrors);
      toast.error(error[0] ? error[0] : "All fields are required", {
        style: {
          padding: '18px',
        }
      });

    }

  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const userType = localStorage.getItem('userType');

      if (userType === 'User' || userType === 'Employer') {
        navigate("/");
      }
    }
  }, []);

  
  const handleSuccess = (token) => {
    console.log("Login successful:", token);
  };

  const handleFailure = (error) => {
   
    console.error("Login failed:", error);
  };

  const handleGoogleLoginSuccessEmployer = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);

      console.log("Decoded token:", decoded);

      const response = await axios.post("/api/employer/googleLoginEmployer", {
        tokenId: credentialResponse.credential,
      });

      console.log("Server response:", response.data);

      const { token, user } = response.data;

      if (token) {
        localStorage.setItem('token', token);

        if (user.role === 'Employer') {
          try {
            const data = await fetch("/api/user/me", {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "token": localStorage.getItem('token')
              }
            });
            const json = await data.json();
            const logData = json.user;
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
            console.log("Loaded user data:", logData);
          } catch (error) {
            console.error("Error loading user data:", error);
          }

          localStorage.setItem('userType', 'Employer');
        } 
        navigate("/");
      } else {
        console.log("Login failed:", response.data.message);
      }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);

      console.error("Error during Google login:", error);
    }
  };


  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
  };


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const myButton = document.querySelector('.btnsign');
        if (myButton) {
          myButton.click();
        } else {
          console.error('Button not found');
        }
      }
    };
    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyPress);
    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  return (
    <>



      {
            /* <div className="signup-container">
                <div className="student-signup">
                    <div className="content">
                        <img src={SignupImage1} alt="signup" />
                        <img src={SignupImage2} alt="signup" />
                    </div>
                    <form onSubmit={signup} className="form-box">
                        <div className="form">
                            <h2 className="title">Sign Up</h2>
                            <div className="input-box">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" onChange={handleUserChange} id="email" />
                            </div>
                            <div className="input-box">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" onChange={handleUserChange} id="password" />
                            </div>
                            <div className="name-box">
                                <div className="input-box">
                                    <label htmlFor="firstname">Firstname</label>
                                    <input type="text" name='firstname' onChange={handleUserChange} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="lastname">Lastname</label>
                                    <input type="text" name='lastname' onChange={handleUserChange} />
                                </div>
                            </div>
                            <div className="input-box">
                                <label htmlFor="lastname">Mobile Number</label>
                                <div style={{ display: "grid", gridTemplateColumns: "20% 78%", gap: "1rem" }}>
                                    <input type="text" name='countryPhoneCode' onChange={handleUserChange} />
                                    <input type="text" name="phone" onChange={handleUserChange} id="phone" />
                                </div>
                            </div>
                            <p className="label">By Signing up, you agree to <a href="/">Terms & Conditions.</a></p>
                            <button className="btn btn-primary" style={{ width: "100%" }}>
                                {loading ? <Spinner /> : <span style={{ fontSize: "1.6rem" }}>Signup</span>}
                            </button>
                            <a href='/' className="text login-btn">Already Registered! Login?</a>
                        </div>
                    </form>
                </div>
            </div> */}
     <div className="signup-container container-fluid py-4">
        <div className="studentsignup my-3 container" >
          <div className="row mx-auto">

            <div className="hideMob col-lg-7 col-md-7 col-sm-12 mx-auto mb-5 pt-5 " >
              <div style={{ justifyContent: "center" }}>
                <h3 className="title mx-3">
                  <b>Hire experienced job seekers, freshers, and interns faster </b>

                  <p style={{ fontSize: "16px", marginTop: '-8px' }}><b>Post a job now</b></p>
                </h3>
                <p className="para12345 mx-3">
                "Unlock the potential of your organization by joining our exclusive employer 
                portal. Discover top talent seamlessly with our user-friendly platform, where 
                posting, managing, and tracking job opportunities has never been easier. Elevate 
                your recruitment strategy and connect with the brightest minds ready to contribute 
                to your company's success. The future of your workforce starts here."
                </p>
               {/* <div className="row my-5">
                  <div className="col-lg-1 col-md-1 col-sm-1 mb-3">
                    <ul
                      className="list-unstyled  ml-3"
                      style={{ display: "inline-flex" }}
                    >
                      <li className="mx-a1">
                        <img className="avtar" src={SignupImage1} alt="signup" />
                      </li>
                      <li className="mx-auto">
                        <img className="avtar" src={SignupImage2} alt="signup" />
                      </li>
                      <li className="mx-auto ">
                        <img className="avtar" src={SignupImage1} alt="signup" />
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 mb-3 pt-1">
                    <p className="parahike mt-2 mx-2">
                      3k+ internship seekers joined us, now it’s your turn
                    </p>
                  </div>
          </div> */}
                <div className="text-center imagesection" >
                  <img src={Recruiter} alt="recruiter" style={{ marginTop: "30px" }} />
                </div>
              </div>
            </div>




            <div className="col-lg-5 col-md-5 col-sm-12 my-5 mx-auto studentform">
              <form className="form-box">
                <div className="form" style={{height:"517px"}}>
                <h2 className="title text-center" style={{marginTop: "-40px"}}>Sign Up</h2>
                  <TextField
                    type="text"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin = "normal"
                    name="name"
                    onChange={handleUserChange}
                    id="name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-user" style={{ fontSize: "20px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin = "normal"
                    name="email"
                    onChange={handleUserChange}
                    id="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-envelope" ></i>
                        </InputAdornment>
                      ),
                    }}
                  />


                  <TextField
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin='normal'
                    name="password"
                    onChange={handleUserChange}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-lock" style={{ fontSize: "20px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />

        
                    <TextField
                      type="number"
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="phone"
                      onChange={handleUserChange}
                      id="phone"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="fa fa-mobile" style={{ fontSize: "28px", marginLeft: "2px" }}></i>
                          </InputAdornment>
                        ),
                      }}
                    />
                    
                  {/* <input type="checkbox"></input><span><p className="label">
                  By Signing up, you agree to{" "}
                  <a href="/">Terms & Conditions.</a>
                </p></span> */}
                  <button className="btn btnsign" style={{ width: "100%", marginTop: "10px" , paddingTop:'16px', paddingBottom:'16px'}} onClick={signup}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <span style={{ fontSize: "1.6rem", fontWeight:'800' }}>Sign Up</span>
                    )}
                  </button>
                  <p className="text-center pt-4" style={{ fontSize: "13px" ,marginTop:'12px'}}>
                    Already have an Account?{" "}
                    <a href="/login" className="font-weight-bold" style={{ fontSize: "13px",marginLeft:'0px' }}>
                      Log In
                    </a>
                  </p>
                  {/* <div className="row my-2">
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                    <div className="col-2 my-4 text-center">
                      <p style={{ fontWeight: "500", color: "#7A7474" }}>or</p>
                    </div>
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                  </div> */}
                  {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "-10px" }}>
                    <GoogleOAuthProvider clientId="100664819392-m58df630lnjhu1u5lnl8sgn72n330u0u.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccessEmployer}
                        onError={handleGoogleLoginFailure}
                      />
                    </GoogleOAuthProvider>

                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
{/*
      <div className="hire-stats">
        <div className="box">
          <h2 className="title ">Why to Hire on Hirekingdom?</h2>
          <p style={{ color: 'gray', fontSize: "15px", marginTop: "-10px", paddingBottom: "10px" }}>Post your job and hire candidates</p>
        </div>
        <div className='box'>
          <div className="stat-box">
            <div className="stat st1">
              <div className='container lt' style={{ textAlign: 'start', marginRight: "auto", maxWidth: "180px", width: "80%" }}>
                <strong className="number" style={{ color: "#2998D0" }}>21Mn+</strong>
                <p className="description" style={{ marginTop: '-15px', fontSize: "13px" }}>candidates looking for jobs and internships</p>
              </div>
            </div>
            <div className="stat st2">
              <div className='container lt' style={{ textAlign: 'start', maxWidth: "150px", width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                <strong className="number" style={{ color: "#2998D0" }}>21Mn+</strong>
                <p className="description" style={{ marginTop: '-15px', fontSize: "13px" }}>candidates hired PAN India</p>
              </div>
            </div>
            <div className="stat st3">
              <div className='container lt' style={{ textAlign: 'start', maxWidth: "180px", width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                <strong className="number" style={{ color: "#2998D0" }}>21Mn+</strong>
                <p className="description" style={{ marginTop: '-15px', fontSize: "13px" }}>job profiles in business, tech, and more</p>
              </div>
            </div>
            <div className="stat st4">
              <div className='container lt' style={{ textAlign: 'start', marginLeft: "auto", maxWidth: "180px", width: "80%" }}>
                <strong className="number" style={{ color: "#2998D0" }}>21Mn+</strong>
                <p className="description" style={{ marginTop: '-15px', fontSize: "13px" }}>Companies hire on Hirekingdom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
 




      <div className="questions">
        <div className="box">
          <h2 className="title">Frequently Asked Questions</h2>
        </div>

        <div className="accordion js-accordion">
          <div className="accordion__item js-accordion-item">
            <div className="accordion-header js-accordion-header" onClick={openAccordian}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc .</div>
            <div className="accordion-body js-accordion-body">
              <div className="accordion-body__contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse.
                Nostrum voluptatum et repudiandae vel sed, explicabo in?
              </div>
            </div>
          </div>
         
          <div className="accordion__item js-accordion-item">
            <div className="accordion-header js-accordion-header" onClick={openAccordian}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc .</div>
            <div className="accordion-body js-accordion-body">
              <div className="accordion-body__contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in?
              </div>
            </div>
          </div>
          <div className="accordion__item js-accordion-item">
            <div className="accordion-header js-accordion-header" onClick={openAccordian}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc .</div>
            <div className="accordion-body js-accordion-body">
              <div className="accordion-body__contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in?
              </div>
            </div>
          </div>
          <div className="accordion__item js-accordion-item">
            <div className="accordion-header js-accordion-header" onClick={openAccordian}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc .</div>
            <div className="accordion-body js-accordion-body">
              <div className="accordion-body__contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in?
              </div>
            </div>
          </div>
          <div className="accordion__item js-accordion-item">
            <div className="accordion-header js-accordion-header" onClick={openAccordian}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc .</div>
            <div className="accordion-body js-accordion-body">
              <div className="accordion-body__contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in?
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <strong style={{ fontSize: "1.3rem", letterSpacing: .25 }}>Can't find answer to question.
            <a href='/' style={{ color: "#0077E4", paddingLeft: "4px" }}>Send us query now.</a>
          </strong>
          
        </div>
 

      </div>




    */}

    <ToastContainer
      position="top-center"
      autoClose={10000}
      />

    </>
  )
}

export default EmployerSignup