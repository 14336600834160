import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function EmployerprotectedRoute(props) {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let userType = localStorage.getItem('userType');

    if (!userType) {
      navigate('/login');
    } else if (userType === 'User') {
      navigate('/notfound');
    }
  }, [navigate]);

  return <>{localStorage.getItem('userType') === 'Employer' ? <Component /> : null}</>;
}

export default EmployerprotectedRoute;
