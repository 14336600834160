import React, { useState, useEffect } from "react";
import "./css/Review.scss";
import vector1 from "../../assets/images/Vector.png";
import { useLocation, useHistory } from "react-router-dom";
import log from "../../assets/images/ll.png";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const ApplicationDetails = () => {


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState("");


  const calculateDaysDifference = (startDate) => {
    const start = new Date(startDate);
    const end = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start;

    // Convert the difference to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Set the state with the calculated difference
    return differenceInDays;
  };

  const getPersonalDetails= async()=>{
  try {
  const id = state.user? state.user: ""
  const { data } = await axios.post("/api/employer/getpersonaldetails", {id},{
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
  setUser(data.RESULT_DATA[0])
   } catch (error) {
     console.log(error);
}
}


useEffect(() => {
  getPersonalDetails();
   window.scrollTo(0, 0)
 },[]);


  return (
    <div className="reviewpage container-fluid px-5 py-5">

   
      <div className="r1 container">
      <h2 className="mb-3"><MdOutlineArrowBack className="mr-5" style={{fontSize: "30px", cursor: "pointer"}}  onClick={() => navigate('/employerApplication/Internship')} />Applicants Details</h2>
      <div className="section3 mb-4" style={{ marginTop: "30px"}}>
          <div className="heading">
            <h2 className="title pt-2" style={{fontSize: "22px"}}>
              Personal Detail
            </h2>
          </div>
         
          <div className="row p-5">
            <div className="col-2" style={{minWidth: '150px'}}>
             
                {user && user.avatar? (<>
                  <img src={user.avatar.url} alt=".." style={{height: "170px", width: '170px', borderRadius: "100px", backgroundColor: "#E3E5E6", marginTop: "10px"}}/>
                </>):(<>
                  <div style={{height: "170px", width: '170px', borderRadius: "100px", backgroundColor: "#E3E5E6", marginTop: "10px"}}>
                  <img src={log} alt=".." style={{marginLeft: "65px", marginTop: "55px"}}/>
                  </div>
                </>)}
                
             
            </div>
            <div className="col-5" style={{paddingLeft: '60px', paddingRight: "40px"}}>
            <div for="exampleInputEmail1"><b>Name</b></div>
                <input
                  type="text"
                  class="inputApp "
                  style={{ fontSize: "15px" }}
                  value= {state.resume.PersonalDetails.firstName+" "+state.resume.PersonalDetails.lastName}
                  readOnly
                />
              <div style={{marginTop: "15px"}}></div>
                 <div for="exampleInputEmail1"><b>Phone No.</b></div>
                <input
                  type="text"
                  class="inputApp "
                  style={{ fontSize: "15px" }}
                  value= {state.resume.PersonalDetails.phone}
                  readOnly
                />
            </div>
            <div className="col-5" style={{paddingLeft: '40px', paddingRight: "40px"}}>
            <div for="exampleInputEmail1"><b>Email</b></div>
                <input
                  type="text"
                  class="inputApp "
                  style={{ fontSize: "15px" }}
                  value= {state.resume.PersonalDetails.email}
                  readOnly
                />
                 <div style={{marginTop: "15px"}}></div>
                <div for="exampleInputEmail1"><b>Location</b></div>
                <input
                  type="text"
                  class="inputApp "
                  style={{ fontSize: "15px" }}
                  value= {state.resume.PersonalDetails.address}
                  readOnly
                />
            </div>
          </div>

        </div>

        <div className="section3" style={{ marginTop: "50px"}}>
          <div className="heading">
            <h2 className="title pt-2" style={{fontSize: "22px"}}>
              Application
              {calculateDaysDifference(state.appliedDate).toFixed(0) == 0?
              <span className="aplied float-right" style={{fontSize: "17px"}}>Applied today</span>:
              <span className="aplied float-right" style={{fontSize: "17px"}}>Applied {calculateDaysDifference(state.appliedDate).toFixed(0)} days ago</span>
            }
              
            </h2>
          </div>
          <div className="information py-3 px-3 mx-4 ">
            <h3 className="cover my-3" style={{fontSize: "20px"}}>Cover letter</h3>
            <h4 className="hire mt-5 mb-2" style={{fontSize: "16px", color: "black"}}>
              Why should you be hired for this role?
            </h4>
            <p className="para mb-3 mt-4" style={{fontSize: "16px", color: "#59595A"}}>
              {state.coverLetter}
            </p>
            <h3 className="cover my-3 mt-5" style={{fontSize: "20px"}}>Availability</h3>
            <p className="para my-3" style={{fontSize: "16px", color: "#59595A"}}>
              {state.availability?  "Yes, I am available for this opportunity.":"No, I am not available for this opportunity." }
           
            </p>


          
          </div>
        </div>

        {state.resume.PersonalDetails? (<>
        <div className="section3 mb-4 " style={{marginTop: "50px"}}>
          <div className="heading">
            <h2 className="title pt-2" style={{fontSize: "22px"}}>
              Resume
            </h2>
          </div>
          <div className=" px-4 py-5 ">
        {/* <div class="container">
                <h1>{state.resume.PersonalDetails.firstName} {state.resume.PersonalDetails.lastName}</h1>
                <div style={{ color: 'black' }}>
                  <p>{state.resume.PersonalDetails.email}</p>
                  <p style={{ marginTop: '-10px' }}>+ 91 {state.resume.PersonalDetails.phone}</p>
                  <p style={{ marginTop: '-10px' }}>{state.resume.PersonalDetails.address}</p>
                </div>
          </div>
          <hr class="dropdown-divider my-5" />*/}

          {state.resume.Education.length != 0 ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>EDUCATION</h3>
              </div>
              <div class="col-9">
                  {state.resume.Education.map((data) => {
                    return ( <> <div style={{ marginBottom: "10px", color: "#4A4E50", fontSize: '17px' }}>
                              <p><b>{data.degree}</b></p>
                              <div style={{ color: 'black', marginTop: '-8px' }}>
                                <p>{data.school}</p>
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(5, 7) + "/" + (data.startDate).slice(0, 4)} - {(data.endDate).slice(5, 7) + "/" + (data.endDate).slice(0, 4)}</p>
                                <p style={{ marginTop: "-8px" }}>{data.performanceScale} : {data.performance}</p>
                              </div>
                            </div> </>) })  }
              </div>
            </div>
          </div>
          <hr class="dropdown-divider my-5" />
          </>) : (<></>)}

          {state.resume.Job.length != 0  ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>JOBS</h3>
              </div>
              <div class="col-9">

              
                  {state.resume.Job.map((data) => {
                    return ( <>  <div style={{ marginBottom: "10px", fontSize: '17px' }}>
                              <p style={{ color: "#4A4E50" }}><b>{data.profile} at {data.organization}</b></p>
                              <div style={{ color: 'black', marginTop: '-8px' }}>
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(5, 7) + "/" + (data.startDate).slice(0, 4)} - {(data.endDate).slice(5, 7) + "/" + (data.endDate).slice(0, 4)}</p>
                                <p style={{ marginTop: "-8px" }}>{data.description}</p>
                              </div>
                            </div> </> ) }) }
              </div>
            </div>
          </div>
          <hr class="dropdown-divider my-5" />
          </>) : (<></>)}

          {state.resume.Internship.length != 0  ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>INTERNSHIPS</h3>
              </div>
              <div class="col-9">
                
                  {state.resume.Internship.map((data) => {
                    return ( <><div style={{ marginBottom: "10px", fontSize: '17px' }}>
                              <p style={{ color: "#4A4E50" }}><b>{data.profile}</b></p>
                              <div style={{ color: 'black', marginTop: '-8px' }}>
                              <p style={{ marginTop: "-8px" }}>{data.organization}</p>
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(5, 7) + "/" + (data.startDate).slice(0, 4)} - {(data.endDate).slice(5, 7) + "/" + (data.endDate).slice(0, 4)}</p>
                               
                              </div>
                            </div>  </>)})}
              </div>
            </div>
          </div>
          <hr class="dropdown-divider my-5" />
          </>) : (<></>)}

          {state.resume.Training.length != 0  ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>TRAININGS/COURSES</h3>
              </div>
              <div class="col-9">

              
                  {state.resume.Training.map((data) => {
                    return ( <> <div style={{ marginBottom: "10px", fontSize: '17px' }}>
                              <p style={{ color: "#4A4E50" }}><b>{data.program} at {data.organization}</b></p>
                              <div style={{ color: 'black',  marginTop: '-8px' }}>
                              <p style={{ marginTop: "-8px" }}>Courserar Online</p>
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(5, 7) + "/" + (data.startDate).slice(0, 4)} - {(data.endDate).slice(5, 7) + "/" + (data.endDate).slice(0, 4)}</p>
                              </div>
                            </div>  </>)}) }
              </div>
            </div>
          </div>
          <hr class="dropdown-divider my-5" />
          </>) : (<></>)}

          {state.resume.Academics.length != 0  ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>ACADEMICS/PERSONAL PROJECTS</h3>
              </div>
              <div class="col-9">
              
                  {state.resume.Academics.map((data) => {
                    return ( <> <div style={{ marginBottom: "10px", fontSize: '17px' }}>
                              <p style={{ color: "#4A4E50" }}><b>{data.title}</b></p>
                              <div style={{ color: 'black',  marginTop: '-8px' }}>
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(5, 7) + "/" + (data.startDate).slice(0, 4)} - {(data.endDate).slice(5, 7) + "/" + (data.endDate).slice(0, 4)}</p>
                                <a target="_black" href={data.link} style={{ color: "#0F93C8", marginTop: '-8px' }}>{data.link}</a>
                                <p style={{marginTop: '8px'}}>{data.description}</p>
                               
                              </div>
                            </div> </>) }) }
              </div>
            </div>
          </div>
          <hr class="dropdown-divider my-5" />
          </>) : (<></>)}

          {state.resume.Skills.length != 0  ? (<>
          <div class="container">
            <div class="row">
              <div class="col-3">
                <h3 style={{ color: "black" }}>SKILLS</h3>
              </div>
              <div class="col-5">

               <div style={{display: "flex", flexWrap: "wrap", gap: "20%"}}>
                 {state.resume.Skills.map((data) => {
                    return (
                      <>
                        <div style={{ marginBottom: "10px", fontSize: '17px' }}>
                              <p style={{ color: "#4A4E50" }}><b>{data.name}</b></p>
                              <div style={{ color: 'black',  marginTop: '-8px' }}>
                                <p style={{marginTop: '8px'}}>Intermediate</p>
                              </div>
                            </div>
                             </>  )    })  }
                             </div>
              </div>
              <div class="col-4">
              </div>
            </div>
          </div>
          </>) : (<></>)}   
            </div>
          </div>
        </>) : (<></>)}
     
      </div>  
      </div>
  );
};

export default ApplicationDetails;
