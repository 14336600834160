import React, { useEffect, useState, useRef } from "react";
import "./css/PersonalDetails.scss";
import Perso from "../../assets/images/perso.png";
import Org from "../../assets/images/org.png";
import Post from "../../assets/images/post.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinner from "../Layout/Spinner";
import { Avatar } from "@mui/material";
import { skillOptions } from "../data";
import photo from "../../assets/images/icons/profile.png";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';


import citiesData from './citiesData.json';
import Popup from 'reactjs-popup';


const options = [
  { value: 'Accounting & Finance', label: 'Accounting & Finance' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Business Service', label: 'Business Service' },
  { value: 'Chemical', label: 'Chemical' },
  { value: 'Civil', label: 'Civil' },
  { value: 'Computer Science', label: 'Computer Science' },
  { value: 'Content Writing ', label: 'Content Writing ' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Health Care', label: 'Health Care' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Mechanical', label: 'Mechanical' },
  { value: 'Other', label: 'Other' },
  { value: 'Restaurant', label: 'Restaurant' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Transportation', label: 'Transportation' },
];


const PersonalDetails = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);



  const inputRef = useRef(null);
  const [avatar, setAvatar] = useState("");
  const [prev, setPrev] = useState(
    {
      orgLogo: "",
    }
  );
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handlepostnewButton = () => {
    window.location.reload();
    setStep(1);
    console.log("Button clicked!")
  };

  const handleviepostedjobButton = () => {
    // Navigate to the MatchedJob page
    // navigate('/employerApplication');
    navigate('/employerApplication/Internship');
    console.log("Button clicked!")
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  
  const [users, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",

  });

  const [organisation, setOrganisation] = useState({
    email: "",
    organisationName: "",
    organisationDescription: "",
    organisationAvatar: "",
    organisationURL: "",
  });

  const [job, setJob] = useState({
    email: "",
    opportunityType: "",
    jobType: "",
    profile: "",
    skill: [],
    numberOfOpeining: "",
    city: [],
    // state: "",
    country: "",
    isPartTimeAllowed: "No",
    duration: "",
    salaryProfile: "",
    salary: "",
    perks: "",
    category: "",
    jobDescription: " ",
  });

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");



  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedCity("");
    setJob({
      ...job,
      country: country,
      city: ""
    });
  };
  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    setJob({
      ...job,
      city: selectedOptions.map(option => option.value), // Assuming city is an array in job state
    });
  };
  // const handleSkillChange = (newValue, actionMeta) => {

  //   setSelectedSkills(newValue);
  // };

  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleProfileChange = (newValue) => {
    setSelectedProfile(newValue);
  };

  const predefinedOptions = [
    { value: 'Android App Development', label: 'Android App Development' },
    { value: 'Web Development', label: 'Web Development' },
    { value: 'iOS App Development', label: 'iOS App Development' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'Data Science', label: 'Data Science' },
    { value: 'Machine Learning', label: 'Machine Learning' },
    { value: 'Full Stack Development', label: 'Full Stack Development' },
    { value: 'Game Development', label: 'Game Development' },
    { value: 'DevOps', label: 'DevOps' },
    { value: 'Cloud Computing', label: 'Cloud Computing' },
    { value: 'Cybersecurity', label: 'Cybersecurity' },
    { value: 'Digital Marketing', label: 'Digital Marketing' },
    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
    { value: 'Blockchain Development', label: 'Blockchain Development' },
    { value: 'Network Administration', label: 'Network Administration' },
    { value: 'Database Management', label: 'Database Management' },
    { value: 'Content Writing', label: 'Content Writing' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'Video Editing', label: 'Video Editing' },
    { value: 'Mobile Game Development', label: 'Mobile Game Development' },
    { value: 'AR/VR Development', label: 'AR/VR Development' },
    { value: 'Embedded Systems', label: 'Embedded Systems' },
  ];

  const createProfileOption = (inputValue) => ({
    label: inputValue,
    value: inputValue,
  });


  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const handleUserChange = (e) => {
    setUser({
      ...users,
      [e.target.name]: e.target.value,

    });
  };

  useEffect(() => {
    setUser(user && user);
    setOrganisation({
      ...organisation,

      email: user && user.email,
      organisationName: user && user.organisationName,
      organisationDescription: user && user.organisationDescription,
      organisationAvatar: ""
    });

    setPrev({
      ...prev,
      orgLogo: user && user.organisationAvatar != undefined || "" ? user.organisationAvatar.url : "",
    })


  }, [user]);

  const handleOrganisationChange = (e) => {
    setOrganisation({
      ...organisation,
      email: user.email,
      [e.target.name]: e.target.value,
    });
  };

  const handleJobChange = (e) => {
    setJob({
      ...job,
      email: user.email,
      [e.target.name]: e.target.value,
    });
  };

  const handleJobDescriptionChange = (e) => {
    setJob({
      ...job,
      jobDescription: e.target.value
    });
  }



  const handleUpate = async () => {
    setLoading(true);
    if (!users.name) {
      setLoading(false);
      toast.error("Name is required!!");
    } else if (!users.email) {
      setLoading(false);
      toast.error("Email is required!!");
    } else if (!users.phone) {
      setLoading(false);
      toast.error("Phone is required!!");
    } else if (!users.city) {
      setLoading(false);
      toast.error("City is required!!");
    } else {
      try {
        const { data } = await axios.post("/api/user/updateuser", {
          name: users.name,
          email: users.email,
          phone: users.phone,
          city: users.city,
          avatar,
        });
        setLoading(false);

        toast.success("Details Updated Successfully!", {
          style: {
            paddingTop: "18px",
            paddingBottom: "18px",
          },
        });
        const timer = setTimeout(() => {
          setStep(step + 1);
        }, 1000);

        timer();
      } catch (error) {
        setLoading(false);

        toast.error(error.response.data, {
          style: {
            padding: "18px",
          },
        });
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        // setAvatarPrev(Reader.result);

        // setAvatar(Reader.result);
        if (Reader.result) {
          setOrganisation({
            ...organisation,

            organisationAvatar: Reader.result,

          });

          setPrev({
            ...prev,

            orgLogo: Reader.result,

          });
        }
      }
    };
  };

  const handleOrganisationUpate = async () => {
    setLoading(true);
    if (!organisation.organisationName) {
      setLoading(false);
      toast.error("Organisation name is required!!");
    } else if (!organisation.organisationDescription) {
      setLoading(false);
      toast.error("Organisation description is required!!");
    } else {
      try {
        const { data } = await axios.post("/api/user/updateorg", organisation);

        setLoading(false);
        toast.success("Details Updated Successfully!", {
          style: {
            paddingTop: "18px",
            paddingBottom: "18px",
          },
        });
        const timer = setTimeout(() => {
          setStep(step + 1);
        }, 1000);

        timer();
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data, {
          style: {
            padding: "18px",
          },
        });
      }
    }
  };

  const handleJobUpdate = async () => {
    setLoading(true);
    if (!job.opportunityType) {
      setLoading(false);
      toast.error("Opportunity type is required!!");
    } else if (!job.jobType) {
      setLoading(false);
      toast.error("Job/Internship type is required!!");
    } else if (!job.profile) {
      setLoading(false);
      toast.error("Job/Internship profile is required!!");
    } else if (!job.skill) {
      setLoading(false);
      toast.error("skill is required!!");
    } else if (!job.numberOfOpeining) {
      setLoading(false);
      toast.error("No. of Opeining is required!!");
    } else if (!job.country) {
      setLoading(false);
      toast.error("Country is required!!");
      // } else if (!job.state) {
      //   setLoading(false);
      //   toast.error("State is required!!");
    } else if (!job.city) {
      setLoading(false);
      toast.error("City is required!!");
    } else if (!job.category) {
      setLoading(false);
      toast.error("Category is required!!");
    } else if (!job.duration) {
      setLoading(false);
      toast.error("Duration is required!!");
    } else if (!job.salary) {
      setLoading(false);
      toast.error("Salary is required!!");
    } else {
      try {
        const { data } = await axios.post("/api/employer/postJob", job, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });


        setLoading(false);
        setShowSuccessPopup(true);
        // toast.success("Job/Internship Saved Successfully!", {
        //   style: {
        //     paddingTop: "18px",
        //     paddingBottom: "18px",
        //   },
        // });
        // const timer = setTimeout(() => {
        //   navigate("/employerApplication");
        // }, 1000);

        // timer();

      } catch (error) {
        setLoading(false);
        setShowErrorPopup(true);
        // toast.error(error.response.data, {
        //   style: {
        //     padding: "18px",
        //   },
        // });
      }
    }
  };

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStepIcons = () => {
    const steps = [
      "Personal details",
      "Organization details",
      "Post internship/jobs",
    ];




    return (
      <div className="step-icons">
        {steps.map((label, index) => (
          <div
            key={label}
            className={`step-icon ${index + 1 === step ? "active" : ""}`}
          >
            {label}
          </div>
        ))}
      </div>
    );
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="multistep-form container">
              <div className="stepsonly mx-auto">
                <div
                  className="steppping"
                  style={{
                    marginRight: "130px",
                    backgroundColor: "#D4D5D8",
                    borderStyle: "solid",
                    borderColor: "#4B92C8",
                  }}
                >
                  <img src={Perso} alt="Account" />
                </div>

                <div
                  className="steppping"
                  style={{ marginRight: "130px", backgroundColor: "#D4D5D8" }}
                >
                  <img src={Org} alt="Account" />
                </div>

                <div
                  className="steppping steppp"
                  style={{ backgroundColor: "#D4D5D8" }}
                >
                  <img src={Post} alt="Account" />
                </div>
              </div>

              <div className="stepso mx-auto">
                <div
                  className="steppping"
                  style={{ marginRight: "80px", width: "200px" }}
                >
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Personal details
                  </pre>
                </div>

                <div className="steppping" style={{ marginRight: "70px" }}>
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Organization details
                  </pre>
                </div>

                <div className="steppping steppp">
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Post internship/jobs
                  </pre>
                </div>
              </div>
            </div>

            <div className="step-content container ">
              <h3
                className=" title text-center mx-auto"
                style={{ marginTop: "50px", marginBottom: "20px" }}
              >
                Personal Details
              </h3>
              <div className="sectionn ml-auto" style={{ margin: "0" }}>
                <div className="section1 mx-auto" style={{ maxWidth: "800px" }}>
                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      placeholder="John Deo"
                      value={users && users.name}
                      onChange={handleUserChange}
                      id="name"
                      name="name"
                    />
                  </div>
                  <div class="form-group my-4">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      placeholder="Johndeo@example.com"
                      value={users && users.email}
                      onChange={handleUserChange}
                      id="email"
                      name="email"
                      readOnly
                    />
                  </div>
                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Phone
                    </label>
                    <input
                      type="email"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      placeholder="Enter Phone No."
                      onChange={handleUserChange}
                      id="phone"
                      name="phone"
                      value={users && users.phone}
                    />
                  </div>
                  <div class="form-group my-4">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      City
                    </label>
                    <input
                      type="tel"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      placeholder="Enter City Name"
                      onChange={handleUserChange}
                      id="city"
                      name="city"
                      value={users && users.city}
                    />
                  </div>
                  <div
                    className="float-right"
                    style={{ marginTop: "50px", marginRight: "-30px" }}
                  >


                    <button onClick={handleUpate} className="steps btn ">
                      {loading ? <Spinner /> : <span>Next</span>}
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            {" "}
            <div className="multistep-form container">
              <div className="stepsonly mx-auto">
                <div
                  className="steppping stp1"
                  style={{ marginRight: "130px", backgroundColor: "#4B92C8" }}
                >
                  <img src={Perso} alt="Account" />
                </div>

                <div
                  className="steppping"
                  style={{
                    marginRight: "130px",
                    backgroundColor: "#D4D5D8",
                    borderStyle: "solid",
                    borderColor: "#4B92C8",
                  }}
                >
                  <img src={Org} alt="Account" />
                </div>

                <div
                  className="steppping steppp"
                  style={{ backgroundColor: "#D4D5D8" }}
                >
                  <img src={Post} alt="Account" />
                </div>
              </div>

              <div className="stepso mx-auto">
                <div
                  className="steppping"
                  style={{ marginRight: "80px", width: "200px" }}
                >
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Personal details
                  </pre>
                </div>

                <div className="steppping" style={{ marginRight: "70px" }}>
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Organization details
                  </pre>
                </div>

                <div className="steppping steppp">
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Post internship/jobs
                  </pre>
                </div>
              </div>
            </div>
            <div className="step-content container">
              <h3
                className=" title text-center mx-auto"
                style={{ marginTop: "50px", marginBottom: "20px" }}
              >
                Organization Details
              </h3>
              <div
                className="sectionn my-3 mx-auto"
                style={{ maxWidth: "800px" }}
              >
                <div className="section1">
                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Organization Name
                    </label>
                    <input
                      type="text"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      value={organisation && organisation.organisationName}
                      onChange={handleOrganisationChange}
                      id="organisationName"
                      name="organisationName"
                      placeholder="Organization name"
                    />
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="organisationURL" style={{ fontSize: "15px", marginTop: "30px" }}>
                      Organization URL
                    </label>
                    <input
                      type="text"
                      className="form-control py-3"
                      style={{ fontSize: "15px" }}
                      value={organisation && organisation.organisationURL}
                      onChange={handleOrganisationChange}
                      id="organisationURL"
                      name="organisationURL"
                      placeholder="Organization URL"
                    />
                  </div>
                  {/* <div class="form-check my-2">
                    <input
                      type="checkbox"
                      class="form-check-input mt-2"
                      id="exampleCheck1"
                    />
                    <label
                      class="form-check-label mx-2"
                      for="exampleCheck1"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      I am an independent practitioner (freelancer, architect,
                      lawyer etc.) posting for self
                    </label>
                      </div> */}

                  <div class="form-group my-5">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Organization Description
                    </label>
                    <textarea
                      rows="4"
                      cols="50"
                      class="form-control py-3"
                      style={{ fontSize: "15px" }}
                      value={
                        organisation && organisation.organisationDescription
                      }
                      onChange={handleOrganisationChange}
                      id="organisationDescription"
                      name="organisationDescription"
                    />
                  </div>
                  <div class="form-group my-4">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px", marginBottom: "15px" }}
                    >
                      Organization Logo
                      <span style={{ color: "#8A8A8A", fontSize: "15px" }}>
                        {" "}
                        (Recommended)
                      </span>
                    </label>
                    <div class="input-group  ">
                      <div class="custom-file my-4 pt-4 ">
                        <div className="btn"
                          style={{
                            height: "70px",
                            width: "260px",
                            backgroundColor: "#D6EFFD",
                            fontSize: '17px',
                            marginRight: "25px",
                            borderStyle: "dotted",
                            borderColor: "#1E8FC8",
                            color: "#1E8FC8",
                            borderWidth: '2px',
                            borderRadius: "5px",
                            paddingTop: '18px',
                          }}
                          onClick={handleClick}><i class="fa fa-upload pr-3" aria-hidden="true"></i><b>Upload Logo</b></div>



                        <input
                          ref={inputRef}
                          style={{ display: 'none' }}
                          type="file"
                          // class="custom-file-input  "
                          // id="inputGroupFile01"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <Avatar
                          src={prev && prev.orgLogo}
                          alt="User Avatar"
                          sx={{ height: "50px", width: "50px" }}
                        />
                        {/* <label class="custom-file-label " for="inputGroupFile01" >
                        
                      </label> */}
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2 mt-5">
                    <p
                      for="exampleInputEmail1"
                      style={{ color: "gray", fontSize: "14px" }}
                    >
                      Max file size: 1Mb and max resolution: 500px x 500px. File
                      type: jpeg, jpg, png, gift bmp
                    </p>
                  </div>
                </div>
                {/*
          <h3 className=" title text-center mt-5 pt-4 pb-3">
                  Organization verification
                </h3>
                */}
                <div className="section1 mt-4">
                  {   /* 
                  <div className="border m-1 p-4">
                    <div class="form-group my-2 ">
                      <label
                        for="exampleInputEmail1"
                        style={{ fontSize: "15px" }}
                      >
                        Using any one of the options below, get your
                        organization verified and start posting internships/jobs
                      </label>
                    </div>
                    <div class="form-check  mt-5">
                      <input class="form-check-input mt-3" type="radio" />
                      <label
                        class="form-check-label mx-3"
                        style={{ fontSize: "14px" }}
                      >
                        Organization's website
                      </label>
                      <p
                        className="checkeclass mx-3"
                        style={{ fontSize: "13px" }}
                      >
                        Verify using your organization's active & functional
                        website
                      </p>
                    </div>
                    <div class="form-check  mt-5">
                      <input class="form-check-input mt-3" type="radio" />
                      <label
                        class="form-check-label mx-3"
                        style={{ fontSize: "14px" }}
                      >
                        Active social media page
                      </label>
                      <p
                        className="checkeclass mx-3"
                        style={{ fontSize: "13px" }}
                      >
                        Connect your most active social media profile of which
                        you are an admin with minimum 900-1100 likes/followers{" "}
                      </p>
                    </div>
                    <div class="form-check  mt-5">
                      <input class="form-check-input mt-3" type="radio" />
                      <label
                        class="form-check-label mx-3"
                        style={{ fontSize: "14px" }}
                      >
                        Official company documents{" "}
                      </label>
                      <p
                        className="checkeclass mx-3"
                        style={{ fontSize: "13px" }}
                      >
                        Verify using Corporate Identification Number (CIN) or
                        any govt. issued business registration document{" "}
                      </p>
                    </div>
                  </div>*/}
                  <div
                    className="float-right"
                    style={{ marginTop: "50px", marginRight: "-30px" }}
                  >

                    <button className="steps btn mx-3 " onClick={prevStep}>
                      Back
                    </button>
                    <a href="#">
                      <button
                        className="steps btn"
                        onClick={handleOrganisationUpate}
                      >
                        Next
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="multistep-form container">
              <div className="stepsonly mx-auto">
                <div
                  className="steppping stp1"
                  style={{ marginRight: "130px", backgroundColor: "#4B92C8" }}
                >
                  <img src={Perso} alt="Account" />
                </div>

                <div
                  className="steppping stp2"
                  style={{ marginRight: "130px", backgroundColor: "#4B92C8" }}
                >
                  <img src={Org} alt="Account" />
                </div>

                <div
                  className="steppping steppp"
                  style={{
                    backgroundColor: "#D4D5D8",
                    borderStyle: "solid",
                    borderColor: "#4B92C8",
                  }}
                >
                  <img src={Post} alt="Account" />
                </div>
              </div>

              <div className="stepso mx-auto">
                <div
                  className="steppping"
                  style={{ marginRight: "80px", width: "200px" }}
                >
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Personal details
                  </pre>
                </div>

                <div className="steppping" style={{ marginRight: "70px" }}>
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Organization details
                  </pre>
                </div>

                <div className="steppping steppp">
                  <pre style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Post internship/jobs
                  </pre>
                </div>
              </div>
            </div>

            <div className="step-content container ">
              <h3
                className=" title text-center mx-auto"
                style={{ marginTop: "50px", marginBottom: "20px" }}
              >
                Post internships/jobs
              </h3>
              <div className="sectionn ml-auto" style={{ margin: "0" }}>
                <div className="section1 mx-auto" style={{ maxWidth: "800px" }}>
                  <div class="container mt-4" style={{ marginBottom: "20px" }}>
                    <div class="row">
                      <div class="col-4" style={{ minWidth: "200px" }}>
                        <div class="form-group my-2">
                          <label
                            for="exampleInputEmail1"
                            style={{ fontSize: "15px", marginRight: "8px" }}
                          >
                            Opportunity type
                          </label>
                        </div>
                        <div class="form-check form-check-inline ">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="opportunityType"
                            name="opportunityType"
                            value="Internship"
                            onChange={handleJobChange}
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>Internship</b>
                            </pre>
                          </label>
                        </div>
                        <br></br>
                        <div
                          class="form-check form-check-inline "
                          style={{ marginTop: "-10px" }}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="opportunityType"
                            name="opportunityType"
                            value="Job"
                            onChange={handleJobChange}
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>Job</b>
                            </pre>
                          </label>
                        </div>
                      </div>
                      <div class="col-4 " style={{ minWidth: "200px" }}>
                        <div class="form-group my-2 ">
                          <label
                            for="exampleInputEmail1"
                            style={{ fontSize: "15px", marginRight: "8px" }}
                          >
                            Part time allowed
                          </label>
                        </div>
                        <div class="form-check form-check-inline ">
                          <input
                            class="form-check-input"
                            type="radio"
                            value="Yes"
                            onChange={handleJobChange}
                            id="isPartTimeAllowed"
                            name="isPartTimeAllowed"
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>Yes</b>
                            </pre>
                          </label>
                        </div>
                        <br></br>
                        <div
                          class="form-check form-check-inline "
                          style={{ marginTop: "-10px" }}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            value="No"
                            onChange={handleJobChange}
                            id="isPartTimeAllowed"
                            name="isPartTimeAllowed"
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>No</b>
                            </pre>
                          </label>
                        </div>
                      </div>
                      <div class="col-4 " style={{ minWidth: "200px" }}>
                        <div class="form-group my-2">
                          <label
                            for="exampleInputEmail1"
                            style={{ fontSize: "15px", marginRight: "8px" }}
                          >
                            Internship/Job type
                          </label>
                        </div>
                        <div class="form-check form-check-inline ">
                          <input

                            class="form-check-input"
                            type="radio"
                            id="jobType"
                            name="jobType"
                            value="Office"
                            onChange={handleJobChange}
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>Office</b>
                            </pre>
                          </label>
                        </div>
                        <br></br>
                        <div
                          class="form-check form-check-inline "
                          style={{ marginTop: "-10px" }}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="jobType"
                            name="jobType"
                            value="Remote"
                            onChange={handleJobChange}
                          />
                          <label
                            class="form-check-label "
                            for="inlineRadio1"
                            style={{ fontSize: "15px", marginTop: "-3px" }}
                          >
                            <pre style={{ color: "#64676A" }}>
                              {" "}
                              <b>Remote</b>
                            </pre>
                          </label>
                        </div>
                        <div>
                          <div class="form-check form-check-inline " style={{ marginTop: "-10px" }}>
                            <input
                              class="form-check-input"
                              type="radio"
                              id="jobType"
                              name="jobType"
                              value="Hybrid"
                              onChange={handleJobChange}
                            />
                            <label
                              class="form-check-label "
                              for="inlineRadio1"
                              style={{ fontSize: "15px", marginTop: "-3px" }}
                            >
                              <pre style={{ color: "#64676A" }}>
                                {" "}
                                <b>Hybrid</b>
                              </pre>
                            </label>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  {/* <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Internship/job profile
                    </label> */}
                  {/* <input
                      type="text"
                      class="form-control py-3"
                      placeholder="Android App Development"
                      value={job.profile}
                      onChange={handleJobChange}
                      id="profile"
                      name="profile"
                      style={{ fontSize: "15px" }}
                    /> */}

                  {/* <CreatableSelect
                      isClearable
                      onChange={handleProfileChange}
                      onCreateOption={(inputValue) => {
                        handleProfileChange(createProfileOption(inputValue));
                      }}
                      options={predefinedOptions}
                      value={selectedProfile}
                      placeholder="Enter or select a profile"
                    />
                  </div> */}


                  <div className="form-group my-2">
                    <label htmlFor="profile" style={{ fontSize: "15px" }}>
                      Internship/job profile
                    </label>
                    <Select
                      // defaultValue={{ label: job.profile, value: job.profile }}
                      defaultValue={{ label: "Select...", value: job.profile }}

                      onChange={(handleJobChange) => {
                        setJob({
                          ...job,
                          profile: handleJobChange.value,
                        });
                      }}
                      options={predefinedOptions}

                    />
                  </div>


                  {/* <CreatableSelect
                      isClearable
                      onChange={handleProfileChange}
                      onCreateOption={(inputValue) => {
                        handleProfileChange(createProfileOption(inputValue));
                      }}
                      options={predefinedOptions}
                      value={selectedProfile}
                      placeholder="Enter or select a profile"
                    /> */}



                  {/* <div class="form-group my-4">
                    <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                      Skills required
                    </label>
                    <CreatableSelect
                      isMulti // Allow selecting multiple skills
                      options={skillOptions}
                      value={selectedSkills}
                      onChange={handleSkillChange}
                    />
                  </div> */}



                  {/* <div class="form-group my-4">
        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
          Skills required
        </label> */}
                  {/* <CreatableSelect
                      isMulti // Allow selecting multiple skills
                      options={skillOptions}
                      value={selectedSkills}
                      onChange={handleSkillChange}
                    /> */}
                  {/* <input
          type="text"
          class="form-control py-3"
          placeholder="Kotlin (Use comma to separate the skills)"
          value={job.skill}
          onChange={handleJobChange}
          id="skill"
          name="skill"
          style={{ fontSize: "15px" }}
        />
      </div> */}


                  <div class="form-group my-4">
                    <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                      Skills required
                    </label>
                    <Select
                      isMulti   // Enable multi-select
                      defaultValue={job.skill.map((selectedSkill) => ({
                        label: selectedSkill,
                        value: selectedSkill,
                      }))}
                      onChange={(selectedSkills) => {
                        const skillValues = selectedSkills.map((skill) => skill.value);
                        setJob({
                          ...job,
                          skill: skillValues,
                        });
                      }}
                      options={skillOptions}
                    />
                  </div>


                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Number of opeinings
                    </label>
                    <input
                      type="number"
                      class="form-control py-3"
                      placeholder="50"
                      min="0"
                      value={job.numberOfOpeining}
                      onChange={handleJobChange}
                      id="numberOfOpeining"
                      name="numberOfOpeining"
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                  {/* <div class="form-group my-4">
                    <div className="row">
                      <div className="col-4">
                        <label
                          for="exampleInputEmail1"
                          style={{ fontSize: "15px" }}
                        >
                          Country
                        </label>
                        <CountrySelect
                          onChange={(e) => {
                            setCountryid(e.id);
                            setJob({
                              ...job,
                              country: e.name
                            });
                          }}
                          placeHolder="Select Country"
                          value={job.country}

                          id="country"
                          name="country"
                        />
                      </div>
                      <div className="col-4">
                        <label
                          for="exampleInputEmail1"
                          style={{ fontSize: "15px" }}
                        >
                          State
                        </label>
                        <StateSelect
                          countryid={countryid}
                          onChange={(e) => {
                            setstateid(e.id);
                            setJob({
                              ...job,
                              state: e.name
                            });
                          }}
                          placeHolder="Select State"
                        />
                      </div>
                      <div className="col-4">
                        <label
                          for="exampleInputEmail1"
                          style={{ fontSize: "15px" }}
                        >
                          City
                        </label>
                        <CitySelect
                          countryid={countryid}
                          stateid={stateid}
                          onChange={(e) => {
                            setJob({
                              ...job,
                              city: e.name
                            });
                          }}
                          placeHolder="Select City"
                        />
                      </div>
                    </div>


                  </div> */}

                  <div className="form-group my-4">
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="country" style={{ fontSize: "15px" }}>
                          Country
                        </label>
                        <select
                          id="country"
                          className="form-control"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          style={{ width: "100%", height: "40px", fontSize: "13px" }}
                        >
                          <option value="" style={{ fontSize: "14px", fontweight: 'bold' }} >Select a country</option>
                          {Object.keys(citiesData).map((country) => (
                            <option key={country} value={country} style={{ fontSize: "14px" }}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-4">
                        <label htmlFor="city" style={{ fontSize: "15px" }}>
                          City
                        </label>
                        <Select
                          isMulti
                          options={selectedCountry && citiesData[selectedCountry].map(city => ({ value: city, label: city }))}
                          value={selectedCity}
                          onChange={handleCityChange}
                          styles={{ container: (base) => ({ ...base, fontSize: '13px', width: '400px' }) }}
                        />
                      </div>
                    </div>
                  </div>


                  <div class="form-group my-4">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Job/Internship category
                    </label>
                    <Select
                      defaultValue={job.category}
                      onChange={(e) => {
                        setJob({
                          ...job,
                          category: e.value,
                        });
                      }}
                      options={options}

                    />
                  </div>
                  <div class="form-group my-4">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Internship/job duration
                    </label>
                    <input
                      type="number"
                      class="form-control py-3"
                      placeholder="In months"
                      min="0"
                      value={job.duration}
                      onChange={handleJobChange}
                      id="duration"
                      name="duration"
                      style={{ fontSize: "15px" }}
                    />
                  </div>

                  <div className="form-group my-4">
                    <label htmlFor="jobDescription" style={{ fontSize: "15px" }}>
                      Job Description
                    </label>
                    <textarea
                      id="jobDescription"
                      className="form-control"
                      rows="4"
                      onChange={handleJobDescriptionChange}
                      style={{ fontSize: "15px" }}
                      value={job.jobDescription}
                    />

                  </div>

                </div>
              </div>
            </div>

            <div className="step-content container">
              <h3
                className=" title text-center"
                style={{ marginBottom: "20px" }}
              >
                Stipend and perks
              </h3>
              <div
                className="sectionn my-3 mx-auto"
                style={{ maxWidth: "800px" }}
              >
                <div className="section1 py-4">
                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px", marginRight: "8px" }}
                    >
                     Job & Internship Profile
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="salaryProfile"
                      name="salaryProfile"
                      value="Fixed"
                      onChange={handleJobChange}
                    />
                    <label
                      class="form-check-label "
                      for="inlineRadio1"
                      style={{ fontSize: "15px", marginTop: "-3px" }}
                    >
                      <pre
                        style={{
                          fontStyle: "'Roboto', sans-serif",
                          color: "gray",
                          marginRight: "28px",
                        }}
                      >
                        {" "}
                        <b>Fixed</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="salaryProfile"
                      name="salaryProfile"
                      value="Negotiable"
                      onChange={handleJobChange}
                    />
                    <label
                      class="form-check-label "
                      for="inlineRadio1"
                      style={{ fontSize: "15px", marginTop: "-3px" }}
                    >
                      <pre
                        style={{
                          fontStyle: "'Roboto', sans-serif",
                          color: "gray",
                          marginRight: "28px",
                        }}
                      >
                        {" "}
                        <b>Negotiable</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="salaryProfile"
                      name="salaryProfile"
                      value="Unpaid"
                      onChange={handleJobChange}
                    />
                    <label
                      class="form-check-label "
                      for="inlineRadio1"
                      style={{ fontSize: "15px", marginTop: "-3px" }}
                    >
                      <pre
                        style={{
                          fontStyle: "'Roboto', sans-serif",
                          color: "gray",
                          marginRight: "28px",
                        }}
                      >
                        {" "}
                        <b>Unpaid</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="salaryProfile"
                      name="salaryProfile"
                      value="Performence based"
                      onChange={handleJobChange}
                    />
                    <label
                      class="form-check-label "
                      for="inlineRadio1"
                      style={{ fontSize: "15px", marginTop: "-3px" }}
                    >
                      <pre
                        style={{
                          fontStyle: "'Roboto', sans-serif",
                          color: "gray",
                        }}
                      >
                        {" "}
                        <b>Performence based</b>
                      </pre>
                    </label>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <select
                        name="currency"
                        class="form-control mb-3"
                        style={{
                          height:'40px',
                          maxWidth: "60px ",
                          fontSize: "15px",
                        }}
                      >

                        <option value="RUPEES" style={{ fontSize: "15px" }}>
                          ₹
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group ">
                        <input
                          style={{ fontSize: "15px", maxWidth: "200px ", marginLeft: '-30px' }}
                          type="number"
                          min="0"
                          class="form-control py-3"
                          placeholder="10,000"
                          value={job.salary}
                          onChange={handleJobChange}
                          id="salary"
                          name="salary"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">

                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2"></div>
                  </div>
                  <div class="form-group my-2">
                    <label
                      for="exampleInputEmail1"
                      style={{ fontSize: "15px" }}
                    >
                      Perks
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="perks"
                      name="perks"
                      value="Certificate"
                      onClick={handleJobChange}
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio1"
                      style={{ fontSize: "12px" }}
                    >
                      <pre
                        style={{
                          marginTop: "-2px",
                          fontSize: "13px",
                          marginRight: "28px",
                        }}
                      >
                        <b>Certificate</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="perks"
                      name="perks"
                      value="Flexible working hours"
                      onClick={handleJobChange}
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio1"
                      style={{ fontSize: "12px" }}
                    >
                      <pre
                        style={{
                          marginTop: "-2px",
                          fontSize: "13px",
                          marginRight: "28px",
                        }}
                      >
                        <b>Flexible work hour</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline  ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="perks"
                      name="perks"
                      value="Letter of Recommendation"
                      onClick={handleJobChange}
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio1"
                      style={{ fontSize: "12px" }}
                    >
                      <pre
                        style={{
                          marginTop: "-2px",
                          fontSize: "13px",
                          marginRight: "28px",
                        }}
                      >
                        <b>Letter of Recommendation</b>
                      </pre>
                    </label>
                  </div>
                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="perks"
                      name="perks"
                      value="5 days a week"
                      onClick={handleJobChange}
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio1"
                      style={{ fontSize: "12px" }}
                    >
                      <pre style={{ marginTop: "-2px", fontSize: "13px" }}>
                        <b>5 days a week</b>
                      </pre>
                    </label>
                  </div>
                </div>

                <div className="step-content container  my-0 py-0">
             { /*    <h3
                    className=" title text-center "
                    style={{ marginTop: "60px", marginBottom: "20px" }}
                  >
                    Cover letter, availability & assessment question
                      </h3>  */}
                 <div className="sectionn  my-0 py-0 ">
                  {/*  <div className="section1">
                      <div class="form-group ">
                        <label
                          for="exampleInputEmail1"
                          style={{ fontSize: "15px" }}
                        >
                          Cover letter
                        </label>
                        <p style={{ fontSize: "13px" }}>
                          Why should you be hired for this role?
                        </p>
                        <label
                          for="exampleInputEmail1"
                          style={{ fontSize: "15px", marginTop: "20px" }}
                        >
                          Availability
                        </label>
                        <p style={{ fontSize: "13px" }}>
                          Are you available for 1 month, starting immediately,
                          for a full-time work from home internship? If not,
                          what is the time period you are available for and the
                          earliest date you can start this internship on?
                        </p>
                        <h3
                          className="my-5"
                          style={{ color: "#4B92C8", fontSize: "15px" }}
                        >
                          <span>+ </span> Add assessment question
                        </h3>
                      </div>
                      </div> */}
                    <div className="float-right" style={{marginBottom: "0px", marginTop: "20px"}}>
                      <button className="steps btn mx-3 " onClick={prevStep}>
                        Back
                      </button>

                      <button onClick={handleJobUpdate} className="btn steps">
                        Post Job/Internship
                      </button>
                    </div>
                    <Popup
                      open={showSuccessPopup}
                      closeOnDocumentClick
                      onClose={() => setShowSuccessPopup(false)}
                    >
                      <div className="overlay">
                        <div className="popup-container" style={{ width: "45%", height: "52%", padding: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <button className="close-button" onClick={() => setShowSuccessPopup(false)}>
                            X
                          </button>
                          <div>
                            <div className="success-message" style={{ marginTop: '10px', background: '#E1F5CD', padding: '10px' }}>
                              Job/Internship Saved Successfully!
                            </div>
                          </div>
                          <div className="button-container" style={{ marginTop: '40px' }}>
                            <button style={{ marginRight: '20px', padding: '10px', fontSize: '14px' }} onClick={handleviepostedjobButton}>view posted job</button>


                            <button style={{ padding: '10px', fontSize: '14px' }} onClick={handlepostnewButton}>
                              post a new job
                            </button>
                          </div>
                        </div>
                      </div>
                    </Popup>

                    {/* Error Popup */}
                    <Popup
                      open={showErrorPopup}
                      closeOnDocumentClick
                      onClose={() => setShowErrorPopup(false)}
                    >
                      <div className="overlay">
                        <div className="popup-container">
                          <button className="close-button" onClick={() => setShowErrorPopup(false)}>
                            X
                          </button>
                          <div className="error-message" style={{ marginTop: '10px' }}>
                            An error occurred. Please try again.
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return <div>{renderStepContent()}</div>;
};

export default PersonalDetails;
