import React from "react";
import "./css/HelpCenter.scss";
import  { useState } from "react";
import ProfileIcon from "../../assets/images/icons/profile.png";
import InternshipsIcon from "../../assets/images/icons/internships.png";
import ApplcationIcon from "../../assets/images/icons/applications.png";
import OtherIssuesIcon from "../../assets/images/icons/other_issues.png";
import TechnicalIssuesIcon from "../../assets/images/icons/technical_issues.png";
import AssistanceIcon from "../../assets/images/icons/assistance.png";
import option1 from "../../assets/images/option1.png";
import option2 from "../../assets/images/option2.png";
import option3 from "../../assets/images/option3.png";
import option4 from "../../assets/images/option4.png";
import option5 from "../../assets/images/option5.png";
import option6 from "../../assets/images/option6.png";

export default function HelpCenter() {
  const [highlighted, setHighlighted] = useState(false);

  const handleContainerClick = () => {
    setHighlighted(!highlighted);
  };

  return (
    <div className="help-center">
      <div className="help container-fluid text-center">
        <small className="subheading ">Help centre</small>
        <h3 className="title mb-1 mt-3">How can we help you ?</h3>
        <div class="form-group" style={{marginLeft:'-8%'}}>
          <input
          style={{width: "335px",borderTopLeftRadius:'7px',borderBottomLeftRadius:'7px',height:'50px'}}
            type="search"
            class="form-control my-5 py-4 mx-auto"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Search for Answer"
          />
        
        <button className="btn btnsearch" style={{marginTop: "-136px",marginLeft:'28.7%'}}>Search</button>
      </div>
      </div>
      <div className="container optiongroup row mx-7 my-2">
        {/* <div className="row mx-7 my-2" > */}
          <div style={{width:'28%',height:'28%',marginRight:'17px'}}>
            <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option1} alt="Account" style={{ maxWidth: "100%", height: "auto" }} />
                </div>
                <div className="option-info">
                  <h4 className="title">Account / Profile</h4>
                  <p className="paragraph mt-4">
                    Manage your account and profile{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%',marginRight:'17px'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option2} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">Find internship & jobs</h4>
                  <p className="paragraph mt-4">
                  Find internships and jobs matching your preferences on Hirekingom{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option3} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">My application</h4>
                  <p className="paragraph mt-4">
                  Know about your current application status{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28.2%',height:'28%',marginRight:'17px'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option2} alt="Account"/>
                </div>
                <div className="option-info">
                  <h4 className="title">Facing an issue</h4>
                  <p className="paragraph mt-4">
                  Report any complaint you may have against an internship or job{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%',marginRight:'17px'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option5} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">Technical issue</h4>
                  <p className="paragraph mt-4">
                  Report any technical difficulty you are facing here{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option6} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">Need further assistance?</h4>
                  <p className="paragraph mt-4">
                  Can't find what you are looking for? Submit your request here{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
        {/* </div> */}

         {/* <div className="row mx-7 my-5" >
          <div style={{width:'28.2%',height:'28%',marginRight:'17px'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option2} alt="Account"/>
                </div>
                <div className="option-info">
                  <h4 className="title">Facing an issue</h4>
                  <p className="paragraph mt-4">
                  Report any complaint you may have against an internship or job{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%',marginRight:'17px'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option5} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">Technical issue</h4>
                  <p className="paragraph mt-4">
                  Report any technical difficulty you are facing here{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div style={{width:'28%',height:'28%'}}>
          <a href="#" style={{ textDecoration: "none" }}>
              <div className="option text-center">
                <div className="option-img">
                  <img src={option6} alt="Account" />
                </div>
                <div className="option-info">
                  <h4 className="title">Need further assistance?</h4>
                  <p className="paragraph mt-4">
                  Can't find what you are looking for? Submit your request here{" "}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div> */}
      </div>
      {/* <div className="options">
        <a href="/profile" className="option">
          <img src={ProfileIcon} alt="option" />
          <strong>Profile</strong>
        </a>
        <a href="/profile" className="option">
          <img src={InternshipsIcon} alt="option" />
          <strong>Internships/Jobs</strong>
        </a>
        <a href="/profile" className="option">
          <img src={ApplcationIcon} alt="option" />
          <strong>My Applications</strong>
        </a>
        <a href="/profile" className="option">
          <img src={OtherIssuesIcon} alt="option" />
          <strong>Other Issues</strong>
        </a>
        <a href="/profile" className="option">
          <img src={TechnicalIssuesIcon} alt="option" />
          <strong>Technical Issues</strong>
        </a>
        <a href="/profile" className="option">
          <img src={AssistanceIcon} alt="option" />
          <strong>Need Assistance</strong>
        </a>
      </div> */}
    </div>
  );
}
