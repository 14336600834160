export const getAppliedBefore = (date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date);
  const secondDate = new Date(); // If date2 is not provided, use the current date

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  if (diffDays == 0) {
    return "Applied Today";
  } else {
    return `Applied ${diffDays} days ago`;
  }
};

export const getMonthYear = (d) => {
  const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const date = new Date(d);
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${months[month]}'${year?.toString()?.substring(2)}`;
};

export const getMonthDuration = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const oneMonth = 30 * 24 * 60 * 60 * 1000;
  const diffMonths = Math.round(Math.abs((d1 - d2) / oneMonth));

  const years = Math.floor(diffMonths / 12);
  const months = diffMonths % 12;

  if (years) {
    return `${years} years ${months ? `${months} months` : ""}`;
  } else {
    return `${months} months`;
  }
};

function calculateTotalWorkExperience(works) {
  // Sort works by start date
  works.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  let totalMonths = 0;
  let currentStartDate = null;
  let currentEndDate = null;

  for (const work of works) {
    const startDate = new Date(work.startDate);
    const endDate = work.endDate ? new Date(work.endDate) : new Date(); // Use current date if end date is not provided

    if (!currentEndDate && endDate <= currentEndDate) {
      totalMonths += 0;
    } else if (!currentStartDate) {
      // No overlap or consecutive dates
      totalMonths += calculateMonthDifference(startDate, endDate);
      currentStartDate = startDate;
      currentEndDate = endDate;
    } else if (startDate <= currentEndDate) {
      // Overlapping periods
      totalMonths += calculateMonthDifference(currentEndDate, endDate);
      currentEndDate = endDate;
    } else {
      // Gap between periods
      totalMonths += calculateMonthDifference(startDate, endDate);
      currentStartDate = startDate;
      currentEndDate = endDate;
    }
  }

  return totalMonths;
}

function calculateMonthDifference(startDate, endDate) {
  const months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  const monthDifference = months + endDate.getMonth() - startDate.getMonth();
  return monthDifference;
}

export const getTotalWorkExperience = (list1, list2) => {
  if (list1 && list2) {
    const total = calculateTotalWorkExperience([...list1, ...list2]);
    const years = Math.floor(total / 12);
    const months = total % 12;

    if (years) {
      return `${years} years ${months ? `${months} months` : ""}`;
    } else {
      return `${months} months`;
    }
  } else {
    return "";
  }
};
