import React, { useState } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';

const Dropdown = () => {
  const [isHovered, setIsHovered] = useState(false);

  const toggle = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className="dropdown"
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        padding: '4px',
        background: `${isHovered ? '#EEE' : 'transparent'}`,
        fontSize: '14px',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          color: '#484848',
          borderRadius: '50%',
          padding: '3px 6px',
          display: 'inline-block',
        }}
        onClick={toggle}
      >
        <SlOptionsVertical height={18} width={18} />
      </div>

      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: '0',
            zIndex: 1,
            backgroundColor: '#fff',
            boxShadow: '0 2px 6px rgba(0,0,0,.12)',
            borderRadius: '6px',
            padding: '12px 0',
            minWidth: '160px',
            marginTop: '10px',
          }}
        >
          <ul>
            <li>
              <a style={{ margin: '15px 0' }} href="/">
                Mark as Unread
              </a>
            </li>
            <li>
              <a style={{ margin: '15px 0' }} href="/">
                Block chat
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
