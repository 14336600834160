import React from 'react';

const MonthCalculator = ({ startDate, endDate }) => {
    const calculateMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const startMonth = start.getMonth();
        const endMonth = end.getMonth();

        let months = (endYear - startYear) * 12;
        months -= startMonth;
        months += endMonth;

        return months <= 0 ? 0 : months;
    };

    const totalMonths = calculateMonths(startDate, endDate);
    const years = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;

    if (totalMonths <= 12) {
        return <span>({totalMonths == 1? totalMonths+ " month": totalMonths+ " months"}) </span>;
    } else {
        return (
            <span>
                ({years == 1? years+ " year":years+ " years"} {remainingMonths && remainingMonths > 0? remainingMonths == 1? remainingMonths+ " month": remainingMonths+ " months" : <></>})
            </span>
        );
    }
};

export default MonthCalculator;
