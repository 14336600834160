import React, {useEffect} from 'react'
import './css/MyApplications.scss'

function MyApplications() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
      },[]);

    return (
        <div className="my-applications job-application-box">
            <h2 className="title">My Applications</h2>

            <div className="job-applications">
                <div className="job-card">
                    <h3 className="company-title">BlackCoffer</h3>
                    <div className="job-title">Front-end Developer</div>
                    <div className="info-box">
                        <div className="info">
                            <strong>Status</strong>
                            <span className="status">Applied</span>
                        </div>
                        <div className="info">
                            <strong>Applied On</strong>
                            <span className="status">28-02-2023</span>
                        </div>
                    </div>

                    <div className="options">
                        <a href="/">Review</a>
                        <a href="/">Missing Skills</a>
                    </div>
                </div>
                <div className="job-card">
                    <h3 className="company-title">BlackCoffer</h3>
                    <div className="job-title">Front-end Developer</div>
                    <div className="info-box">
                        <div className="info">
                            <strong>Status</strong>
                            <span className="status">Applied</span>
                        </div>
                        <div className="info">
                            <strong>Applied On</strong>
                            <span className="status">28-02-2023</span>
                        </div>
                    </div>

                    <div className="options">
                        <a href="/">Review</a>
                        <a href="/">Missing Skills</a>
                    </div>
                </div>
                <div className="job-card">
                    <h3 className="company-title">BlackCoffer</h3>
                    <div className="job-title">Front-end Developer</div>
                    <div className="info-box">
                        <div className="info">
                            <strong>Status</strong>
                            <span className="status">Applied</span>
                        </div>
                        <div className="info">
                            <strong>Applied On</strong>
                            <span className="status">28-02-2023</span>
                        </div>
                    </div>

                    <div className="options">
                        <a href="/">Review</a>
                        <a href="/">Missing Skills</a>
                    </div>
                </div>
                <div className="job-card">
                    <h3 className="company-title">BlackCoffer</h3>
                    <div className="job-title">Front-end Developer</div>
                    <div className="info-box">
                        <div className="info">
                            <strong>Status</strong>
                            <span className="status">Applied</span>
                        </div>
                        <div className="info">
                            <strong>Applied On</strong>
                            <span className="status">28-02-2023</span>
                        </div>
                    </div>

                    <div className="options">
                        <a href="/">Review</a>
                        <a href="/">Missing Skills</a>
                    </div>
                </div>
                <div className="job-card">
                    <h3 className="company-title">BlackCoffer</h3>
                    <div className="job-title">Front-end Developer</div>
                    <div className="info-box">
                        <div className="info">
                            <strong>Status</strong>
                            <span className="status">Applied</span>
                        </div>
                        <div className="info">
                            <strong>Applied On</strong>
                            <span className="status">28-02-2023</span>
                        </div>
                    </div>

                    <div className="options">
                        <a href="/">Review</a>
                        <a href="/">Missing Skills</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyApplications