import React, { useEffect, useRef, useState } from "react";
import "./css/filters.css";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import Slider from "@mui/material/Slider";
import { CiCircleQuestion } from "react-icons/ci";
import { Tooltip } from "@material-tailwind/react";
import { marks, marks2, marks3 } from "./utils/constants";
// import { skillOptions } from "../data";
import location from "../Employer/citiesData.json";
import axios from "axios";

const Filters = ({ filterInput, setFilterInput, setFilterTrigger, initial, setShowFilter }) => {
  function valuetext(value) {
    return value;
  }

  const handleClearInput = () => {
    setFilterInput(initial);
  };

  const handleChange = (e) => {
    setFilterInput({ ...filterInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFilter(false);
    setFilterTrigger((prev) => prev + 1);
    // setFilterInput(initial)
  };

  const [skillOptions, setSkillOptions] = useState([]);

  // useEffect(() => {
  //   async function fetchSkills() {
  //     const response = await axios.get("/api/data/skills");
  //     const data = await response.data;
  //     if (data) setSkillOptions(data);
  //   }

  //   fetchSkills();
  // }, []);
  return (
    <div className="filters-container">
      <form onSubmit={handleSubmit} className="" action="">
        <h3 style={{ marginBottom: "24px" }}>Filters</h3>

        {/* Location */}
        <div className="filter-type">
          <div>
            Applicants location <Info text="Applicants' whose current location/ hometown is same as job location" />
          </div>
          <Dropdown2
            placeholder="e.g. Mumbai"
            category="applicants_location"
            actual={filterInput}
            setActual={setFilterInput}
            // options={location["India"]}
          />
          {/* <input value={filterInput.applicants_location} name="applicants_location" onChange={handleChange} type="text" placeholder="e.g. Mumbai" /> */}
        </div>

        {/* Work Experience */}
        <div className="filter-type">
          <div>
            Minimum work experience (in years) <Info text="Minimum working experience" />{" "}
          </div>
          <Slider
            aria-label="workex"
            value={filterInput.minimum_work_exprience}
            getAriaValueText={valuetext}
            step={1}
            marks={marks}
            min={0}
            max={5}
            onChange={(e, value) => setFilterInput({ ...filterInput, minimum_work_exprience: value })}
          />
        </div>

        {/* Skills */}
        <div className="filter-type">
          <div>
            Skills <Info text="Applicants' who possess at least one of the mentioned skills" />
          </div>
          <Dropdown2 placeholder="e.g. Java" category="skills" actual={filterInput} setActual={setFilterInput}
          //  options={skillOptions} 

           />
          {/* <input value={filterInput.skills} name="skills" onChange={handleChange} type="text" placeholder="e.g. Java" /> */}
        </div>

        {/* Academic background */}
        <div className="filter-type">
          <div>
            Academic background <Info text="Applicants' who hold preferred degree" />
          </div>
          <Dropdown2
            placeholder="e.g. MBA & similar"
            category="academic_background"
            actual={filterInput}
            setActual={setFilterInput}
            options={["MBA", "Master", "Bachelor"]}
          />
          {/* <input
            name="academic_background"
            value={filterInput.academic_background}
            onChange={handleChange}
            type="text"
            placeholder="e.g. MBA & similar"
          /> */}
        </div>

        {/*Relevance Match */}
        {/* <div className="filter-type">
          <div>
            Relevance Match <Info text="Academic minimum score throughout their academic history (10th, 12th, graduation, post-graduation and PhD)" />{" "}
          </div>
          <Slider
            aria-label="relevance"
            value={filterInput.relevence_match}
            getAriaValueText={valuetext}
            marks={marks3}
            step={1}
            min={0}
            max={2}
            onChange={(e, value) => setFilterInput({ ...filterInput, relevence_match: value })}
          />
        </div> */}

        {/* Gradutaion Year */}
        <div className="filter-type">
          <div>
            Graduation year <Info text="Applicants' last course/degree graduation year" />
          </div>
          <Dropdown filterInput={filterInput} setFilterInput={setFilterInput} />
        </div>

        {/* Academic performance */}
        <div className="filter-type">
          <div>
            Minimum academic performance (years){" "}
            <Info text="Academic minimum score throughout their academic history (10th, 12th, graduation, post-graduation and PhD)" />{" "}
          </div>
          <Slider
            aria-label="academic"
            value={filterInput.academic_performance}
            getAriaValueText={valuetext}
            step={10}
            marks={marks2}
            min={50}
            max={100}
            onChange={(e, value) => setFilterInput({ ...filterInput, academic_performance: value })}
          />
        </div>

        {/* <div className="togglers">
          <div>
            Good cover letters only <Info text="good" />{" "}
          </div>
          <div class="form-check form-switch">
            <input
              checked={filterInput.good_cover_letters_only}
              onChange={() => setFilterInput({ ...filterInput, good_cover_letters_only: !filterInput.good_cover_letters_only })}
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>

        <div className="togglers">
          <div>
            Assignments not sent <Info text="good" />{" "}
          </div>
          <div class="form-check form-switch">
            <input
              checked={filterInput.assignment_not_sent}
              onChange={() => setFilterInput({ ...filterInput, assignment_not_sent: !filterInput.assignment_not_sent })}
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>

        <div className="togglers">
          <div>
            Interview not scheduled <Info text="good" />{" "}
          </div>
          <div class="form-check form-switch">
            <input
              checked={filterInput.Interview_not_schedule}
              onChange={() => setFilterInput({ ...filterInput, Interview_not_schedule: !filterInput.Interview_not_schedule })}
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div> */}

        <div className="button-section">
          <button type="button" onClick={() => handleClearInput()}>
            Clear all
          </button>
          <button type="submit">Show results</button>
        </div>
      </form>
    </div>
  );
};

export default Filters;

const Dropdown = ({ filterInput, setFilterInput }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [value, setValue] = useState([]);

  const handleChange = (data) => {
    var new_list = value;
    if (value.includes(data)) {
      new_list = new_list.filter((i) => i !== data);
    } else {
      new_list = [...new_list, data];
    }

    setValue(new_list);
    setFilterInput({ ...filterInput, graduation_year: new_list });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilterInput((prev) => ({ ...prev, graduation_year: value }));
    const parent = document.getElementById("parent-dropdown");
    const child = document.getElementById("dropdown-box"); // Use class selector

    if (parent && child) {
      child.style.top = `${parent.clientHeight + 4}px`;
    }
  }, [value]);

  const options = ["2023 & before", "2024", "2025", "2026", "2027", "2028 & beyond"];

  return (
    <div ref={dropdownRef} className="d-flex gap-2 flex-column" style={{ position: "relative" }}>
      <div id="parent-dropdown" onClick={() => setDropdown(true)} style={{ position: "relative", width: "100%" }}>
        <div className="date-input">
          {filterInput?.graduation_year?.length == 0 && <span>e.g.2021</span>}
          {filterInput?.graduation_year?.map((i) => (
            <span onClick={() => setValue((prev) => prev.filter((item) => item != i))} className="dates">
              {i} <RxCross2 />
            </span>
          ))}
        </div>
        <div className="drop-icon">
          <IoIosArrowDown />
        </div>
      </div>

      <div id="dropdown-box" className="dropdown" style={{ display: `${dropdown ? "flex" : "none"}` }}>
        {options.map((option, index) => (
          <div key={index} className="d-flex">
            <input
              type="checkbox"
              onChange={() => handleChange(option)}
              checked={filterInput?.graduation_year.includes(option)}
              style={{ width: "22px", height: "22px", cursor: "pointer" }}
            />
            <span>{option}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Info = ({ text }) => {
  return (
    <>
      <Tooltip
        content={
          <div
            style={{
              backgroundColor: " black",
              color: "white",
              padding: "10px",
              borderRadius: "8px",
              fontSize: "12px",
              maxWidth: "200px",
              wordBreak: "break-word",
            }}
          >
            {text}
          </div>
        }
      >
        <span style={{ cursor: "pointer" }}>
          <CiCircleQuestion />
        </span>
      </Tooltip>
    </>
  );
};

const Dropdown2 = ({ placeholder, category, actual, setActual, options }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const handleChange = (data) => {
    var new_list = actual[category];
    if (new_list.includes(data)) {
      new_list = new_list.filter((i) => i !== data);
    } else {
      new_list = [...new_list, data];
    }

    setActual((prev) => ({ ...prev, [category]: new_list }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
      setSearch("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const parent = document.getElementById(category);
    const child = document.getElementById(`${category + "child"}`);
    if (parent && child) {
      child.style.top = `${parent.clientHeight + 4}px`;
    }
  }, [actual]);
  useEffect(() => {
    const fetchMatchingCities = async () => {
      if (search.trim().length >= 2) {
        try {
          const response = await axios.get(`/api/data/get-cities-data`, {
            params: { keyword: search },
          });
          if (Array.isArray(response.data)) {
            setFilteredOptions(response.data);
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        setFilteredOptions([]);
      }
    };

    fetchMatchingCities();
  }, [search]);
  useEffect(()=>{
    async function fetchSkills() {
      if (search.trim()){

        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: search },
          });
          if (Array.isArray(response.data)) {
            setSkillOptions(response.data);
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching skills:', error);
        }
      }
      else{
        setSkillOptions([]);
      }
    }
    fetchSkills();
  },[search])
  return (
    <div ref={dropdownRef} className="d-flex gap-2 flex-column" style={{ position: "relative" }}>
      <div id={category} className="parent-dropdown2" onClick={() => setDropdown(true)}>
        {actual[category]?.length > 0 && (
          <div className="d-flex w-100 flex-wrap gap-2 px-3 pt-2">
            {actual[category].map((items) => (
              <span onClick={() => handleChange(items)} className="dates">
                {items} <RxCross2 />
              </span>
            ))}
          </div>
        )}
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          style={{ outline: "none", width: "100%", border: "none" }}
          placeholder={placeholder}
        />
      </div>
        {category === 'applicants_location' && (

      <div
        id={category + "child"}
        className="dropdown"
        style={{ display: `${dropdown && actual[category].length !== filteredOptions?.length ? "flex" : "none"}` }}
      >
        {filteredOptions
          ?.filter((i) => (i?.toString().toLowerCase().includes(search?.toLowerCase()) || search == "") && !actual[category].includes(i?.toString()))
          .map((option, index) => (
            <div onClick={() => handleChange(option)} key={index} className="d-flex text-capitalize">
              <span>{option}</span>
            </div>
          ))}
      </div>
        )}
      {category === 'skills' && (

      <div
        id={category + "child"}
        className="dropdown"
        style={{ display: `${dropdown && actual[category].length !== skillOptions?.length ? "flex" : "none"}` }}
      >
        {skillOptions
          ?.filter((i) => (i?.toString().toLowerCase().includes(search?.toLowerCase()) || search == "") && !actual[category].includes(i?.toString()))
          .map((option, index) => (
            <div onClick={() => handleChange(option)} key={index} className="d-flex text-capitalize">
              <span>{option}</span>
            </div>
          ))}
      </div>
      )}

      {
        category=== "academic_background" && (
            <div
        id={category + "child"}
        className="dropdown"
        style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
      >
        {options
          ?.filter((i) => (i?.toString().toLowerCase().includes(search?.toLowerCase()) || search == "") && !actual[category].includes(i?.toString()))
          .map((option, index) => (
            <div onClick={() => handleChange(option)} key={index} className="d-flex text-capitalize">
              <span>{option}</span>
            </div>
          ))}
      </div>
        )
      }

      {/* <div
        id={category + "child"}
        className="dropdown"
        style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
      >
        {options
          ?.filter((i) => (i?.toString().toLowerCase().includes(search?.toLowerCase()) || search == "") && !actual[category].includes(i?.toString()))
          .map((option, index) => (
            <div onClick={() => handleChange(option)} key={index} className="d-flex text-capitalize">
              <span>{option}</span>
            </div>
          ))}
      </div> */}
    </div>
  );
};
