import React from "react";
import "./css/MessageStudentSecond.scss";

function MessageStudentSecond() {
  return (
    <div className="message-student">
      <div className="chat-box">
        <div className="recent-chats">
          <div className="tabss pt-3">
            <h5 className="bt mx-4">
              Message from applicants for internship/jobs{" "}
            </h5>
            <div class="bt form-group  mx-4 my-3">
              <select
                class="form-control "
                id="exampleFormControlSelect1"
                style={{ fontSize: "13px" }}
              >
                <option className="my-3">Posted jobs/internship</option>
                <option>
                  <span className="float-right">Posted On</span>
                </option>
                <option>
                  <span>14/03/23 - </span>
                  <span className="font-weight-bold">Internship</span>
                  <span> - PHP developement</span>
                </option>
                <option>
                  <span>14/03/23 - </span>
                  <span className="font-weight-bold">Internship</span>
                  <span> - PHP developement</span>
                </option>
                <option>
                  <span>14/03/23 - </span>
                  <span className="font-weight-bold">Internship</span>
                  <span> - PHP developement</span>
                </option>
              </select>
            </div>
            <div></div>
          </div>
          <div className="chats">
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
            <a href="/" className="chat">
              <strong className="title">BlackCoffer</strong>
              <span className="message">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero,
                maxime magnam?
              </span>
              <span className="date">05/04/2023</span>
              <button className="btn status mt-4">In-touch</button>
            </a>
          </div>
        </div>
        <div className="chatroom">
          <div className="sender-area">
            <div className="profile">
              <strong>BlackCoffer</strong>
              <span>
                Online
              </span>
            </div>
            <div className="options">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-more-vertical"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="12" cy="5" r="1"></circle>
                <circle cx="12" cy="19" r="1"></circle>
              </svg>
            </div>
          </div>
          <div className="message-section">
            <div className="conversations">
              <div className="date-time">12-Jan-2023</div>
              <div className="conversation left">
                <p>
                  Hi Anushka, We came across your profile for Operations
                  internship at Solution Graph and quite liked it. You may view
                  the internship details and if you are interested in the
                  opportunity, please reply to this message and we would let you
                  know regarding next steps. Thanks, Ashok Chandan Solution
                  Graph
                </p>
              </div>
              <div className="conversation right">
                <p>
                  Hi, thank you for your invitation. I am interested in this
                  internship. Please let me know regarding next steps. Thanks,
                  Anushka Gaikwad
                </p>
              </div>
              <div className="date-time">8-Feb-2023</div>
              <div className="conversation left">
                <p>
                  Hi Anushka, We came across your profile for Operations
                  internship at Solution Graph and quite liked it. You may view
                  the internship details and if you are interested in the
                  opportunity, please reply to this message and we would let you
                  know regarding next steps. Thanks, Ashok Chandan Solution
                  Graph
                </p>
              </div>
              <div className="conversation right">
                <p>
                  Hi, thank you for your invitation. I am interested in this
                  internship. Please let me know regarding next steps. Thanks,
                  Anushka Gaikwad
                </p>
              </div>
              <div className="date-time">1-Apr-2023</div>
              <div className="conversation left">
                <p>
                  Hi Anushka, We came across your profile for Operations
                  internship at Solution Graph and quite liked it. You may view
                  the internship details and if you are interested in the
                  opportunity, please reply to this message and we would let you
                  know regarding next steps. Thanks, Ashok Chandan Solution
                  Graph
                </p>
              </div>
              <div className="conversation right">
                <p>
                  Hi, thank you for your invitation. I am interested in this
                  internship. Please let me know regarding next steps. Thanks,
                  Anushka Gaikwad
                </p>
              </div>
              <div className="conversation left">
                <p>
                  Hi Anushka, We came across your profile for Operations
                  internship at Solution Graph and quite liked it. You may view
                  the internship details and if you are interested in the
                  opportunity, please reply to this message and we would let you
                  know regarding next steps. Thanks, Ashok Chandan Solution
                  Graph
                </p>
              </div>
              <div className="conversation right">
                <p>
                  Hi, thank you for your invitation. I am interested in this
                  internship. Please let me know regarding next steps. Thanks,
                  Anushka Gaikwad
                </p>
              </div>
            </div>
            <div className="row mx-4 p-3">
              <span>
                <span className="mx-2">
                  <button className="btn hire">Hire</button>
                </span>{" "}
                <span className="mx-2">
                  <button className="btn int">Not interested</button>
                </span>
                <span className="mx-2">
                  <button className="btn schdeule"> Schedule interview</button>
                </span>
                <span className="mx-2">
                  <button className="btn assign">Send assignment</button>
                </span>
              </span>
            </div>
            <div className="send-message-box">
              <input type="text" placeholder="Write Message...." />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-paperclip"
              >
                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
              </svg>
              <button className="btn btnsend">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageStudentSecond;
