import './css/Card.scss';
import Trending from '../../assets/images/Arrowup.jpg';
import { SlLocationPin } from 'react-icons/sl';
import home from '../../assets/images/home.png';
import { LiaMoneyBillSolid } from 'react-icons/lia';
import { CiCalendar } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Card({ props ,isFilter}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // const openPreviewPageInNewTab = (state) => {
  //   const data = state;
  //   window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());

  // };

  const openPreviewPageInNewTab = (state) => {
    const data = state;
    const url = `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
    return url;
};
  
  const {
    profile,
    organisationName,
    city = [],
    salary,
    salaryMax,
    opportunityType,
    duration,
    durationType,
    jobType,
    employer,
    isPartTimeAllowed,
  } = props;

  console.log();

  const navigate = useNavigate();

  return (
    <div className="card-r" style={{ width: isFilter && screenWidth >= 1024 && screenWidth < 1440 ? '27vw' : (isFilter && screenWidth >= 1440 ? '20vw' : 'auto') }}>
      <div
        className="card-container"
        style={{ background: 'transparent', border: '1px solid #EEE' }}
        // href="/preview"
      >
        <div className="actively-hiring-label">
          <img src={Trending} alt="Arrowup" height={16} width={16} />
          <span style={{ color: '#484848' }}>Actively hiring</span>
        </div>
        <div className="heading">
          <div className="content">
          <a style={{ textDecoration: "none", color: "black" }} href={`/preview/${props.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${props.opportunityType}${employer && employer.organisationName? `-at-${employer.organisationName.replace(/ /g, "-")}`: ""}_${props._id}`.toLowerCase()}>
            <h6 >{profile}</h6></a>
           { <span>{employer && employer.organisationName? employer.organisationName: "" }</span>}
          </div>
          {employer && employer.organisationAvatar? 
          <img src={employer.organisationAvatar?employer.organisationAvatar.url: ""} alt={organisationName} />
         :<></>}          
        </div>
        <ul>
          <li>
            {jobType === 'Remote' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={home}
                  alt="Engineering"
                  style={{
                    width: '14px',
                    marginTop: '-2px',
                  }}
                />
                <span
                  className="wfm1 ml-2"
                  style={{
                    fontSize: '12px',
                    marginRight: '20px',
                  }}
                >
                  Work From Home
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SlLocationPin
                  style={{
                    color: 'gray',
                    fontSize: '12px',
                    marginBottom: '3px',
                    // marginTop: '-2px',
                  }}
                />
                {city.map((data, index) => (
                  <div key={index}>
                    <span
                      style={{
                        fontSize: '12px',
                      }}
                      className="wfm1 ml-2"
                    >
                      {data}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </li>
          <li>
            <LiaMoneyBillSolid /> ₹ {salary}-{salaryMax} /{' '}
            {opportunityType === 'Job' ? 'yearly' : 'monthly'}
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {duration !== null ? <CiCalendar /> : ''}
            {'  '}
            {duration}{' '}
            {duration !== null &&
            durationType !== undefined &&
            durationType !== null
              ? `${durationType}`
              : ''}
          </li>
        </ul>

        <div
          className="footer"
          style={
            {
              // paddingBottom: '10px',
            }
          }
        >
          <div>
            <span className="job-label">{opportunityType}</span>
            {isPartTimeAllowed == 'Yes' ? (
              <span className="job-label" style={{ marginLeft: '10px' }}>
                Part time
              </span>
            ) : (
              <></>
            )}
          </div>

          {/* <button
            onClick={() => openPreviewPageInNewTab(props)}
            className="view-details-cta"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#007bff',
            }}
            // href="/preview"
          > */}
          <a
            href={openPreviewPageInNewTab(props)}
            rel="noreferrer"
            target="_blank"
            className="view-details-cta"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#007bff',
              textDecoration: 'none', // to remove underline
            }}
          >
            View details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
